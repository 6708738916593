import Web3 from "web3";
import BigNumber from "bignumber.js";
import {log} from "@/utils/tools";

const contractList = {};
let _web3 = null;
let _wallet = null;
let _connected = false;
let _chainId = 1;
// const rpcETHUrl = process.env.VUE_APP_ETH_RPC_URL;
const rpcBSCUrl = process.env.VUE_APP_BSC_RPC_URL;

const SupportChainId = [1, 56, 97]

const walletListener = new Map();

const chainConfig = {
    // [SupportChainId[0]]: {
    //     rpcUrl: rpcETHUrl,
    //     web3: null,
    //     dWeb3: null
    // },
    [SupportChainId[1]]: {
        rpcUrl: rpcBSCUrl,
        web3: null,
        dWeb3: null
    },
    [SupportChainId[2]]: {
        rpcUrl: rpcBSCUrl,
        web3: null,
        dWeb3: null
    },
}

export const getContract = (token, abi) => {
    let chainId = _chainId;
    if (chainConfig[chainId]) {
        let contc = contractList[token];
        if (contc) {
            return contc;
        }
        try {
            const _web3 = web3();
            contc = new _web3.eth.Contract(abi, token);
            contractList[token] = contc;
            // log(`token = ${token} contc = ${contc}`)
            return contc;
        } catch (e) {
            log(e)
            return null;
        }
    } else {
        return null;
    }

}

export const startListenWallet = async (update) => {
    try {
        if (!Web3.givenProvider) {
            return;
        }
        const provider = Web3.givenProvider;

        setTimeout(()=>{
            _chainId = new BigNumber(provider.chainId).toNumber();
            log(_chainId)
            connect().then(()=>{
                update?.call();
                for (const [key, callBack] of walletListener) {
                    key;
                    // log(key)
                    callBack?.call();
                }
            });
        }, 600)

        if (provider) {
            provider.on('accountsChanged', function (accounts) {
                if (_wallet != accounts[0]) {
                    _wallet = accounts[0];
                }
                log(accounts)
                update?.call();
                for (const [key, callBack] of walletListener) {
                    log(key)
                    callBack?.call();
                }
            });
            provider.on("networkChanged", function (networkIDstring) {
                log(networkIDstring)
                _chainId = networkIDstring;
                connect().then(()=>{
                    update?.call();
                    for (const [key, callBack] of walletListener) {
                        log(key)
                        callBack?.call();
                    }
                });
            })
        }

    } catch (error) {
        log(error)
    }
}

export const listenWalletChanged = async (key, callBack) => {
    if (key && callBack) {
        callBack.call();
        walletListener.set(key, callBack);
    }
}

export const connect = async () => {
    try {
        let wallets = await web3().eth.requestAccounts();
        _wallet = wallets[0];
        _connected = true;
        return true
    } catch (e) {
        _connected = false;
        log(e)
        return false
    }
}

export const isConnected = () => {
    return _connected;
}

export const isAddress = (value) => {
    return web3().utils.isAddress(value);
}

export const fixWallet = () => {
    if (_wallet && _wallet != null && _wallet.length > 10) {
        return _wallet.substr(0, 4) +".." + _wallet.substr(_wallet.length-2, _wallet.length)
    }
    return "Connect"
}

export const getWallet = () => {
    return _wallet;
}

const web3 = () => {
    let chainId = _chainId;
    // log(`chainId = ${chainId} SupportChainId = ${SupportChainId}`)
    if (chainConfig[chainId]) {
        let configInfo = chainConfig[chainId];
        if (!Web3.givenProvider) {
            if (configInfo.dWeb3) {
                return configInfo.dWeb3;
            }
            _web3 = new Web3(configInfo.rpcUrl);
            configInfo.dWeb3 = _web3
        } else {
            if (configInfo.web3) {
                return configInfo.web3;
            }
            _web3 = new Web3(Web3.givenProvider);
            configInfo.web3 = _web3;
        }
        return _web3;
    } else {
        log("not in")
    }
    return null;

}