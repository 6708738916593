<template>
  <div class="w-full text-white">
    <div class="w-full flex flex-row items-center justify-center mt-16">
      <div class="box" :style="[{
        width: fixScale(540, 0),
        paddingTop: fixScale(88, 10),
        paddingBottom: fixScale(66, 8)
      }]">
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">{{groupInfo.inviteLength}}</div>
        <div class="font-bold" style="white-space: pre-wrap" :style="[{
          fontSize: fixScale(46, 0)
        }]">{{$t('Total team\nInvite')}}</div>
      </div>
      <div class="box" :style="[{
        width: fixScale(540, 0),
        paddingTop: fixScale(88, 10),
        paddingBottom: fixScale(66, 8),
        marginLeft: fixScale(46, 0),
        marginRight: fixScale(46, 0)
      }]">
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">{{members.length}}</div>
        <div class="font-bold" style="white-space: pre-wrap" :style="[{
          fontSize: fixScale(46, 0)
        }]">{{$t('Total team\nmembers')}}</div>
      </div>
      <div class="box" :style="[{
        width: fixScale(540, 0),
        paddingTop: fixScale(88, 10),
        paddingBottom: fixScale(66, 8)
      }]">
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">{{fixPrice(holdeCoin, 8, 0)}}</div>
        <div class="font-bold" style="white-space: pre-wrap" :style="[{
          fontSize: fixScale(46, 0),
        }]">{{$t('Total team\rhold')}}</div>
      </div>
    </div>

    <div class="mt-5">
      <div class="font-bold" :style="[{
        fontSize: fixScale(46, 0)
      }]">{{$t('Referrer')}} :</div>
    </div>
    <div>
      <div class="rounded-md mx-auto mt-3 flex flex-col item-center justify-center py-1 px-1 break-all" style="border: 2px solid #FFFFFF99;" :style="[{
        width: fixScale(1300, 300),
        minHeight: fixScale(72, 36),
      }]">
        {{referrer ? referrer : inviter}}
      </div>
    </div>
    <div v-if="!referrer" @click="bindTap" class="mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center" style="background: linear-gradient(0deg, #1E84E5, #7CD2FB)" :style="[{
      width: fixScale(440, 100),
      height: fixScale(100, 40),
      fontSize: fixScale(50, 18)
    }]">{{$t('Bind')}}</div>

    <div class="mt-5">
      <div class="font-bold" :style="[{
        fontSize: fixScale(46, 0)
      }]">{{$t('Referral Link')}} :</div>
    </div>
    <div>
      <div class="rounded-md mx-auto mt-3 flex flex-col item-center justify-center break-all py-1 px-1" style="border: 2px solid #FFFFFF99;" :style="[{
        width: fixScale(1300, 300),
        minHeight: fixScale(72, 36)
      }]">
        https://{{host}}/{{wallet}}
      </div>
    </div>
    <div @click="copy" class="mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center" style="background: linear-gradient(0deg, #1E84E5, #7CD2FB)" :style="[{
      width: fixScale(440, 100),
      height: fixScale(100, 40),
      fontSize: fixScale(50, 18)
    }]">{{$t('Copy')}}</div>

    <div class="mt-5">
      <div class="font-bold" :style="[{
        fontSize: fixScale(46, 0)
      }]">{{$t('Receiving')}} NFT :</div>
    </div>
    <div>
      <div class="rounded-md mx-auto mt-3 flex flex-col item-center justify-center" style="border: 2px solid #FFFFFF99;" :style="[{
        width: fixScale(520, 200),
        height: fixScale(72, 36),
      }]">
        {{nftNum}}
      </div>
    </div>
    <div @click="claimNFT" class="mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center" style="background: linear-gradient(0deg, #1E84E5, #7CD2FB)" :style="[{
      width: fixScale(440, 100),
      height: fixScale(100, 40),
      fontSize: fixScale(50, 18)
    }]">{{$t('Claim')}}</div>

    <div class="mt-10">
      <div class="rounded-xl mx-auto" style="background: #2F2F2F" :style="[{
        width: fixScale(1720, 300),
        paddingBottom: fixScale(144, 40)
      }]">
        <div class="w-full flex flex-row justify-around items-center pt-3">
          <div class="font-bold" :style="[{
            fontSize: fixScale(36, 14)
          }]">Grade</div>
          <div class="font-bold" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{$t('Address')}}</div>
          <div class="font-bold" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{$t('Purchase')}}</div>
          <div class="font-bold" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{$t('Wor')}}</div>
        </div>
        <van-divider :dashed="true" style="margin-left: 6%; margin-right: 6%"></van-divider>
        <div class="w-full" v-for="item in orderInfos" :key="item.grade+item.owner">
          <div v-if="item.times > 0" class="w-full flex flex-row justify-around items-center py-2">
            <div class="font-normal" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{ item.grade }}</div>
            <div class="font-normal" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{ fixWallet(item.owner, 3, 3) }}</div>
            <div class="font-normal" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{ fixPrice(item.buy, 18, 0) }}</div>
            <div class="font-normal" :style="[{
            fontSize: fixScale(36, 14)
          }]">{{ fixPrice(item.wor, 8, 0) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {copy, errFormatMsg, fixWallet, log} from "@/utils/tools";
import {getContract, isAddress} from "@/utils/contract";
import IDOAbi from "../../assets/abi/IDOAbi.json"
// import WORAbi from "../../assets/abi/WORAbi.json"
import BigNumber from "bignumber.js";
import Web3 from "web3";

export default {
  name: "index",
  computed: {
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    },
    fixPrice() {
      return (p, n, d) => {
        if (d == -1) {
          return new BigNumber(p).div((10**n)+"").toNumber()
        }
        return new BigNumber(p).div((10**n)+"").toFixed(d)
      }
    },
    fixWallet() {
      return (_wallet, start, end) => {
        return fixWallet(_wallet, start, end);
      }
    },
    wallet() {
      return this.$store.state.wallet ?? "";
    },
    host() {
      return window.location.host;
    },
  },
  mounted() {
    const self = this;
    this.inviter = window.location.pathname;
    log(`this.inviter = ${this.inviter}`)
    log(`window.location = ${window.location}`)
    if (this.inviter != undefined) {
      this.inviter = this.inviter.replace("/", "");
    }
    this.web3 = new Web3("https://bsc-dataseed1.binance.org");
    const web3 = this.web3;
    // https://bsc-dataseed1.binance.org

    let idoC;

    setInterval(()=> {
      self.loadValues();
      self.analysis();
      self.loadBalance(self.members);
    }, 6000);

    setInterval(() => {
      self.getLogs(idoC);
    }, self.timeClock);
    log("333")
    setTimeout( () => {
      self.loadValues();
      try {
        log("222")
        const worIdo = process.env.VUE_APP_IDO;
        log(worIdo)
        // const web3 = new Web3("https://speedy-nodes-nyc.moralis.io/53e8cd5b6a4efc7a70a311cf/bsc/mainnet");
        idoC = new web3.eth.Contract(IDOAbi, worIdo);
        self.getLogs(idoC);
      } catch (e) {
        log(e)
      }
    }, 1000)


  },
  data() {
    return {
      timeClock: 6000,
      web3: null,
      fromBlock: 18900228,
      inviter: "",
      referrer: "",
      zero: "0x0000000000000000000000000000000000000000",
      nftNum: 0,
      groupInfo: {
        inviteLength: 0,
        memberLength: 0,
        holdeCoin: 0,
      },
      orderInfos: [
        // {
        //   grade: 0,
        //   buy: 0,
        //   owner: "0xBc906703009eFaBF25f1ce7074a0168522DE3644",
        //   inviter: "0x",
        //   wor: 0,
        //   times: 1
        // }
      ],
      inviterInfo: {},
      orderInfo: {},
      membersTmp: [],
      members: [],
      holdeCoin: 0,
      haveCheck: {},
      haveBind: {},
    }
  },
  methods: {
    copy() {
      copy(this, `https://${this.host}/${this.wallet}`)
    },
    async bindTap() {
      const self = this;
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(this.wallet)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const idoC = getContract(worIdo, IDOAbi);
        await idoC.methods.bindReferrer(this.inviter).estimateGas({gas: 5000000, from: this.wallet});
        const result = await idoC.methods.bindReferrer(this.inviter).send({
          from: this.wallet
        });
        if (result.status == true) {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Bind Success'),
          });
        } else {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Bind Failed'),
          });
        }
      } catch (e) {
        this.$notify({
          title: self.$t('Tips'),
          message: errFormatMsg(e, "Bind Failed"),
        });
      }
    },
    async analysis() {
      if (!this.wallet || !isAddress(this.wallet)) {
        log(this.wallet)
        return
      }
      const beInviterList = this.inviterInfo[this.wallet];
      if (beInviterList == undefined || beInviterList.length == 0) {
        log(beInviterList)
        log("beInviterList")
        return;
      }
      let _ol = [];
      for (let i = 0; i < beInviterList.length; i++) {
        const owner = beInviterList[i];
        const _sol = this.orderInfo[owner];
        if (_sol != undefined && _sol.length > 0) {
          _ol = _ol.concat(_sol);
        } else {
          _ol.push({
            grade: i,
            buy: 0,
            owner: owner,
            wor: 0,
            times: 1,
          });
        }
      }
      this.orderInfos = _ol;
      this.membersTmp = [];
      this.haveCheck = {};
      this.loadMembers(beInviterList);
      const members = this.membersTmp;
      this.members = members;
    },
    loadMembers(owners) {
      log("owners")
      log(owners)
      for (let i = 0; i < owners.length; i++) {
        const owner = owners[i];
        if (this.haveCheck[owner]) {
          continue;
        }
        this.haveCheck[owner] = true;
        this.membersTmp.push(owner);
        log(owner)
        const beInviterList = this.inviterInfo[owner];
        if (beInviterList == undefined || beInviterList.length == 0) {
          continue;
        }
        for (let j = 0; j < beInviterList.length; j++) {
          const _o = beInviterList[i];
          if (this.haveCheck[_o]) {
            continue;
          }
          this.membersTmp.push(_o);
          log(_o)

          const _bb = this.inviterInfo[_o];
          if (_bb == undefined || _bb.length == 0) {
            continue;
          }
          this.loadMembers(_bb);
        }
      }

    },
    async loadBalance(members) {
      log(`members`)
      log(members)
      const worIdo = process.env.VUE_APP_IDO;
      const idoC = getContract(worIdo, IDOAbi);
      let tmpList = [];
      let _abs = new BigNumber("0");
      for (let i = 0; i < members.length; i++) {
        tmpList.push(members[i]);
        if (tmpList.length >= 500 || i == members.length-1) {
          const _bs = await idoC.methods.balancesOf(tmpList).call();
          _abs = _abs.plus(_bs);
          tmpList = [];
        }
      }
      this.holdeCoin = _abs.toFixed(0);
      log(this.holdeCoin)
    },
    async loadValues() {
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(this.wallet)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const idoC = getContract(worIdo, IDOAbi);

        const referrer = await idoC.methods.referrerByAddr(this.wallet).call();
        log(`referrer = ${referrer}`)
        if (referrer != this.zero) {
          this.referrer = referrer;
        }

        const _userInfo = await idoC.methods.userInfoOf(this.wallet).call();
        this.nftNum = new BigNumber(_userInfo.nftNum).minus(_userInfo.nftClaimedNum).toFixed(0);

        this.groupInfo = await idoC.methods.groupInfo(this.wallet).call();
        // const orderInfos = await idoC.methods.ordersFrom(this.wallet, 0, 200).call();
        // const _os = [];
        // for (let i = 0; i < orderInfos.length; i++) {
        //   if (orderInfos[i].times > 0 && orderInfos[i].owner != this.zero) {
        //     _os.push(orderInfos[i]);
        //   }
        // }
        // this.orderInfos = _os;
        // log(`11 = ${this.orderInfos}`)
      } catch (e) {
        log("x")
        log(e)
      }
    },
    async claimNFT() {
      const self = this;
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(this.wallet)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const idoC = getContract(worIdo, IDOAbi);
        await idoC.methods.claimNFT().estimateGas({gas: 5000000, from: this.wallet});
        const result = await idoC.methods.claimNFT().send({
          from: this.wallet
        });
        if (result.status == true) {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Claim NFT Success'),
          });
        } else {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Claim NFT Failed'),
          });
        }
      } catch (e) {
        this.$notify({
          title: self.$t('Tips'),
          message: errFormatMsg(e, "Claim NFT Failed"),
        });
      }
    },
    async getLogs(idoC) {
      const self = this;
      try {
        const web3 = this.web3;
        let toBlock = await web3.eth.getBlockNumber();
        let fromBlock = this.fromBlock;
        if (toBlock - this.fromBlock > 5000) {
          toBlock = this.fromBlock+5000;
        }
        this.fromBlock = toBlock+1;

        idoC.getPastEvents("NewBind", {
          fromBlock: fromBlock,
          toBlock: toBlock,
          filter: {},
        }, (error, events) => {
          log(error)
          log(events)
          if (error) {
            return
          }
          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const result = event.returnValues;
            let _list = self.inviterInfo[result.inviter];
            if (_list == undefined) {
              _list = [];
            }
            if (this.haveBind[result.owner]) {
              continue;
            }
            _list.push(result.owner);
            this.haveBind[result.owner] = true;

            self.inviterInfo[result.inviter] = _list;
          }
        })

        idoC.getPastEvents("NewOrder", {
          fromBlock: fromBlock,
          toBlock: toBlock,
          filter: {},
        }, (error, events) => {
          log(error)
          log(events)
          if (error) {
            return
          }
          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const result = event.returnValues;
            let _list = self.orderInfo[result.owner];
            if (_list == undefined) {
              _list = [];
            }
            _list.push(result);
            self.orderInfo[result.owner] = _list;
          }
        })
      } catch (e) {
        log("get logs")
        log(e)
      }
    },
  }
}
</script>

<style scoped>
.box {
  background-image: url("../../assets/images/invite/border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>