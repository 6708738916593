var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',[_c('img',{staticClass:"mt-20 mx-auto",style:([{
      width: _vm.fixScale(180, 60),
      height: _vm.fixScale(180, 60)
    }]),attrs:{"src":require("../../assets/images/nft-head2.png")}})]),_c('div',{staticClass:"mt-10 flex flex-wrap",style:([{
    paddingLeft: _vm.fixScale(100, 20),
    paddingRight: _vm.fixScale(100, 20)
  }])},_vm._l((_vm.nftList),function(item){return _c('div',{key:item.id+item.name,staticClass:"mx-2 rounded-xl text-white",staticStyle:{"background-color":"#2F2F2F"},style:([{
      width: _vm.fixScale(400, 150),
      height: _vm.fixScale(520, 218),
    }])},[_c('img',{staticClass:"rounded-t-xl",staticStyle:{"object-fit":"cover"},style:([{
         width: _vm.fixScale(400, 150),
         height: _vm.fixScale(400, 150),
      }]),attrs:{"src":item.image}}),_c('div',{staticClass:"text-left ml-3"},[_c('div',{staticClass:"font-bold",style:([{
        fontSize: _vm.fixScale(32, 16)
      }])},[_vm._v(_vm._s(item.name))]),_c('div',{style:([{
        fontSize: _vm.fixScale(28, 16)
      }])},[_vm._v("#"+_vm._s(item.id))])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }