var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen bg-bgColor flex flex-col relative"},[_c('div',{staticStyle:{"padding-top":"64px"}},[_c('router-view')],1),_c('div',{staticClass:"w-full bg-red fixed flex flex-row items-center justify-between",staticStyle:{"height":"64px","background-color":"#2A2929"}},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"menu",staticStyle:{"margin-left":"10px"},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('i',{staticClass:"el-icon-s-operation",staticStyle:{"color":"#FFFFFFCC","font-size":"24px"}})]),_c('img',{staticClass:"mr-6 ml-6 subNav",attrs:{"src":require("../assets/images/logo.png"),"width":"99px","height":"auto","alt":""}}),_c('img',{staticClass:"mr-6 ml-2 menu",attrs:{"src":require("../assets/images/logo.png"),"width":"49px","height":"auto","alt":""}})]),_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"subNav",on:{"click":function($event){return _vm.toView('home')}}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('Home')))])]),_c('div',{staticClass:"subNav",on:{"click":function($event){return _vm.toView('about')}}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('About')))])]),_c('div',{staticClass:"subNav",on:{"click":function($event){return _vm.toView('develop')}}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('Develop')))])]),_c('div',{staticClass:"subNav",on:{"click":function($event){return _vm.toView('question')}}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('Question')))])]),_c('router-link',{staticClass:"subNav",attrs:{"to":"/invite"}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('Invite')))])]),_c('router-link',{staticClass:"subNav",attrs:{"to":"/info"}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('Info')))])]),_c('router-link',{staticClass:"subNav",attrs:{"to":"/ido"}},[_c('div',{staticClass:"font-medium mr-6",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }])},[_vm._v(_vm._s(_vm.$t('IDO')))])]),_c('div',{staticClass:"flex flex-row items-center justify-center"},[_c('div',{staticClass:"font-medium mr-2 fontFix h-full",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }]),on:{"click":function($event){return _vm.language('en')}}},[_vm._v("English")]),_c('div',{staticStyle:{"height":"80%","width":"2px","background-color":"#FFFFFF8C"}}),_c('div',{staticClass:"font-medium ml-2 fontFix",staticStyle:{"color":"#FFFFFFCC"},style:([{
            fontSize: _vm.fixScale(26, 14)
          }]),on:{"click":function($event){return _vm.language('zh')}}},[_vm._v("中文")]),_c('div',{staticClass:"ml-2 flex flex-row items-center mr-2 md: ml-4",on:{"click":_vm.connect}},[_c('img',{style:([{
              width: _vm.fixScale(32, 20.5),
              height: _vm.fixScale(28, 18),
            }]),attrs:{"src":require("../assets/images/wallet.png"),"alt":""}}),_c('div',{staticClass:"ml-1 fontFix",staticStyle:{"color":"#FFFFFFCC"},style:([{
              fontSize: _vm.fixScale(26, 14)
            }])},[_vm._v(_vm._s(_vm.fixWallet))])]),_c('router-link',{staticClass:"ml-2 flex flex-row items-center mr-2 md:mr-4",attrs:{"to":"/nft"}},[_c('img',{style:([{
              width: _vm.fixScale(42, 20),
              height: _vm.fixScale(42, 20),
            }]),attrs:{"src":require("../assets/images/nft-head.png"),"alt":""}})])],1)],1)]),_c('van-popup',{style:({ width: '40%', height: '100%'}),attrs:{"position":"left","duration":"0.2"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"w-full h-full",staticStyle:{"padding-top":"44px","background-color":"#2A2929","color":"#FFFFFFCC"}},[_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toView('home')}}},[_vm._v(" "+_vm._s(_vm.$t('Home'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toView('about')}}},[_vm._v(" "+_vm._s(_vm.$t('About'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toView('develop')}}},[_vm._v(" "+_vm._s(_vm.$t('Develop'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toView('question')}}},[_vm._v(" "+_vm._s(_vm.$t('Question'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toRoute('/invite')}}},[_vm._v(" "+_vm._s(_vm.$t('Invite'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toRoute('/info')}}},[_vm._v(" "+_vm._s(_vm.$t('Info'))+" ")]),_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.toRoute('/ido')}}},[_vm._v(" "+_vm._s(_vm.$t('IDO'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }