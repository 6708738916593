var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full text-white"},[_c('div',{staticClass:"w-full flex flex-row items-center justify-center mt-16"},[_c('div',{staticClass:"box",style:([{
      width: _vm.fixScale(540, 0),
      paddingTop: _vm.fixScale(88, 10),
      paddingBottom: _vm.fixScale(66, 8)
    }])},[_c('div',{staticClass:"font-bold",style:([{
        fontSize: _vm.fixScale(80, 0)
      }])},[_vm._v(_vm._s(_vm.groupInfo.inviteLength))]),_c('div',{staticClass:"font-bold",staticStyle:{"white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(46, 0)
      }])},[_vm._v(_vm._s(_vm.$t('Total team\nInvite')))])]),_c('div',{staticClass:"box",style:([{
      width: _vm.fixScale(540, 0),
      paddingTop: _vm.fixScale(88, 10),
      paddingBottom: _vm.fixScale(66, 8),
      marginLeft: _vm.fixScale(46, 0),
      marginRight: _vm.fixScale(46, 0)
    }])},[_c('div',{staticClass:"font-bold",style:([{
        fontSize: _vm.fixScale(80, 0)
      }])},[_vm._v(_vm._s(_vm.members.length))]),_c('div',{staticClass:"font-bold",staticStyle:{"white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(46, 0)
      }])},[_vm._v(_vm._s(_vm.$t('Total team\nmembers')))])]),_c('div',{staticClass:"box",style:([{
      width: _vm.fixScale(540, 0),
      paddingTop: _vm.fixScale(88, 10),
      paddingBottom: _vm.fixScale(66, 8)
    }])},[_c('div',{staticClass:"font-bold",style:([{
        fontSize: _vm.fixScale(80, 0)
      }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.holdeCoin, 8, 0)))]),_c('div',{staticClass:"font-bold",staticStyle:{"white-space":"pre-wrap"},style:([{
        fontSize: _vm.fixScale(46, 0),
      }])},[_vm._v(_vm._s(_vm.$t('Total team\rhold')))])])]),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"font-bold",style:([{
      fontSize: _vm.fixScale(46, 0)
    }])},[_vm._v(_vm._s(_vm.$t('Referrer'))+" :")])]),_c('div',[_c('div',{staticClass:"rounded-md mx-auto mt-3 flex flex-col item-center justify-center py-1 px-1 break-all",staticStyle:{"border":"2px solid #FFFFFF99"},style:([{
      width: _vm.fixScale(1300, 300),
      minHeight: _vm.fixScale(72, 36),
    }])},[_vm._v(" "+_vm._s(_vm.referrer ? _vm.referrer : _vm.inviter)+" ")])]),(!_vm.referrer)?_c('div',{staticClass:"mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center",staticStyle:{"background":"linear-gradient(0deg, #1E84E5, #7CD2FB)"},style:([{
    width: _vm.fixScale(440, 100),
    height: _vm.fixScale(100, 40),
    fontSize: _vm.fixScale(50, 18)
  }]),on:{"click":_vm.bindTap}},[_vm._v(_vm._s(_vm.$t('Bind')))]):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"font-bold",style:([{
      fontSize: _vm.fixScale(46, 0)
    }])},[_vm._v(_vm._s(_vm.$t('Referral Link'))+" :")])]),_c('div',[_c('div',{staticClass:"rounded-md mx-auto mt-3 flex flex-col item-center justify-center break-all py-1 px-1",staticStyle:{"border":"2px solid #FFFFFF99"},style:([{
      width: _vm.fixScale(1300, 300),
      minHeight: _vm.fixScale(72, 36)
    }])},[_vm._v(" https://"+_vm._s(_vm.host)+"/"+_vm._s(_vm.wallet)+" ")])]),_c('div',{staticClass:"mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center",staticStyle:{"background":"linear-gradient(0deg, #1E84E5, #7CD2FB)"},style:([{
    width: _vm.fixScale(440, 100),
    height: _vm.fixScale(100, 40),
    fontSize: _vm.fixScale(50, 18)
  }]),on:{"click":_vm.copy}},[_vm._v(_vm._s(_vm.$t('Copy')))]),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"font-bold",style:([{
      fontSize: _vm.fixScale(46, 0)
    }])},[_vm._v(_vm._s(_vm.$t('Receiving'))+" NFT :")])]),_c('div',[_c('div',{staticClass:"rounded-md mx-auto mt-3 flex flex-col item-center justify-center",staticStyle:{"border":"2px solid #FFFFFF99"},style:([{
      width: _vm.fixScale(520, 200),
      height: _vm.fixScale(72, 36),
    }])},[_vm._v(" "+_vm._s(_vm.nftNum)+" ")])]),_c('div',{staticClass:"mt-5 rounded-lg mx-auto font-bold flex flex-col justify-center",staticStyle:{"background":"linear-gradient(0deg, #1E84E5, #7CD2FB)"},style:([{
    width: _vm.fixScale(440, 100),
    height: _vm.fixScale(100, 40),
    fontSize: _vm.fixScale(50, 18)
  }]),on:{"click":_vm.claimNFT}},[_vm._v(_vm._s(_vm.$t('Claim')))]),_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"rounded-xl mx-auto",staticStyle:{"background":"#2F2F2F"},style:([{
      width: _vm.fixScale(1720, 300),
      paddingBottom: _vm.fixScale(144, 40)
    }])},[_c('div',{staticClass:"w-full flex flex-row justify-around items-center pt-3"},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v("Grade")]),_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.$t('Address')))]),_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.$t('Purchase')))]),_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.$t('Wor')))])]),_c('van-divider',{staticStyle:{"margin-left":"6%","margin-right":"6%"},attrs:{"dashed":true}}),_vm._l((_vm.orderInfos),function(item){return _c('div',{key:item.grade+item.owner,staticClass:"w-full"},[(item.times > 0)?_c('div',{staticClass:"w-full flex flex-row justify-around items-center py-2"},[_c('div',{staticClass:"font-normal",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(item.grade))]),_c('div',{staticClass:"font-normal",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.fixWallet(item.owner, 3, 3)))]),_c('div',{staticClass:"font-normal",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.fixPrice(item.buy, 18, 0)))]),_c('div',{staticClass:"font-normal",style:([{
          fontSize: _vm.fixScale(36, 14)
        }])},[_vm._v(_vm._s(_vm.fixPrice(item.wor, 8, 0)))])]):_vm._e()])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }