import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import Vuex from 'vuex'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Vant from 'vant';
import 'vant/lib/index.css';

import './styles/globals.css'

import i18n from "./i18n/index";

import './assets/css/name.css'


Vue.use(Vuex)

Vue.use(VueRouter)
Vue.use(Element)
Vue.use(Vant);

import VueClipboard from 'vue-clipboard2'

import Home from "@/pages/home";
import Invite from "@/pages/invite";
import IDO from "@/pages/ido";
import About from "@/pages/about";
import Develop from "@/pages/develop"
import National from "@/pages/national"
import Question from "@/pages/question"
import NFT from "@/pages/nft"
import Info from "@/components/Info";


Vue.use(VueClipboard)

Vue.config.productionTip = false

const routes = [
// {
  //   path: '/group',
  //   component: Group,
  //   children: [
  //     {path: "/gNode", component: GNormalNode},
  //     {path: "/gsNode", component: GSuperNode},
  //   ],
  // },
  {path: "/home", component: Home},
  {path: "/invite", component: Invite,},
  {path: "/ido", component: IDO},
  {path: "/about", component: About},
  {path: "/develop", component: Develop},
  {path: "/develop", component: Develop},
  {path: "/national", component: National},
  {path: "/question", component: Question},
  {path: "/nft", component: NFT},
  {path: "/info", component: Info},
  {path: "/", redirect: "/home"}

]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from,next) => {
  // console.log("to = %s,  from = %s", to.name, from.name)
  if (to.path == from.path) {
    return
  }
  next();
})

const store = new Vuex.Store({
  state: {
    bgColor: "transparent",
    wallet: "",
    fixWallet: "",
    scale: 1,
  },
  mutations: {
    updateBgColor (state, bgColor) {
      state.bgColor = bgColor;
    },
    updateWallet(state, wallet) {
      state.wallet = wallet;
    },
    updateFixWallet(state, fixWallet) {
      state.fixWallet = fixWallet;
    },
    setScale(state, value) {
      state.scale = value;
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
