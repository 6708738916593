export default {
    "Home": "主页",
    "About": "关于",
    "Develop": "开发",
    "National": "国家",
    "Question": "问题",
    "Invite": "邀请",
    // "Inspire": "激励",
    // "Workout": "锻炼",
    // "WORKOUT PROTOCOL": "锻炼协议",
    "SPORTS AGREEMENT": "运动协议",
    "NFT TRADE": "NFT 交易",
    "STAGE": "阶段",
    "1": "第一",
    "2": "第二",
    "3": "第三",
    // "buy-hold-earn": "购买-持有-赚币",
    "Buy+hold+make money": "购买+持有+赚钱",
    // "workout-to-earn": "锻炼赚币",
    "Exercise makes money": "运动锻炼即赚钱",
    "Smart wearable devices+\nmeta-space+sports \nto make money.": "智能可穿戴设备+\n元宇宙空间+\n运动。",
    "NINE ADVANTAGES": "九大优势",
    "AGREEMENT\nCASTING AND\nISSUING": "协议 铸造\n发行",
    "PLEDGE-FREE\nCOMPOUND\nINTEREST\nDOUBLE DIVIDEND": "免质押复利\n双重红利",
    "TRANSACTION\nDESTRUCTION": "交易\n销毁",
    "ADDRESS\n SCATTERING": "地址打散",
    "COMMUNITY\n AUTONOMY": "社区自治",
    "CHIBI\n COMPOUND \nINTEREST": "持币复利",
    "WEB3.0 \nECOLOGICAL\n LANDING": "WEB3.0 \n生态落地",
    "IN CASE \nOF \nCHIBI \nPROJECT": "万一持币计划",
    "NFT DIVIDEND \nMECHANISM \nAND ASSET \nTRADING \nPLATFORM": "NFT分红机制\n资产交易平台",
    "ABOUT INSWO": "关于 INSWO",
    "Inspire Workout It aims at engaging in all kinds of sports from motivated participants through the third generation Internet Web3.0, Blockchain technology, Token economy, and a variety of intelligent physical somatosensory monitoring wearable devices. Combining NFT, DeFi, Social-Fi, Metaverse, Game-Fi, Dao and other organizations should be expanded. Specifically, even if smart phones and various fitness wearable devices track data, physical sensors such as GPS position, pressure, temperature, humidity, temperature, rate, etc. track all kinds of data about the surrounding environment and body functions, which reflects the wearer's exercise style. By working out to earn token, we can create the concept of minimal fitness, and want to do fitness anytime and anywhere, so that exercise becomes more convenient, and make health melt, keep breathing and exercise." : "Inspire Workout 旨在通过第三代互联⽹Web3.0，区块链技术Blockchain，通证经济Token economy，结合多种智能物理体感监测穿戴设备，从⽽激励⽣态参与者进⾏各式运动。通过结合NFT，DeFi，Social-Fi，Metaverse，Game-Fi，Dao组织 等进⾏⽣态应⽤拓展。具体来讲，即使⽤智能⼿机及各种健身运动穿戴设备追踪数据，通过GPS位置，压⼒，温度，湿度，⾼度，⼼率检测设备等物理传感器追踪周围环境和身体机能等各种数据，体现出穿戴者的运动⽅式，通过workout to earn的⽅式赚取token，致⼒于打造极简健身，随时随地想要健身运动的理念，让运动变得更加便捷，让健康融⼊⽣活，呼吸不停，运动不⽌。",
    "INSWO PROMOTING HEALTHY SPORTS DEVELOP": "Inswo推进健康运动的发展",
    "Aiming at designing a digital asset linked to sports health data, DAO organization said that every WOR cast and issued by agreement in WOR2.0 era must be supported by sports data of certain value.": "旨在设计一种与运动健康数据挂钩的数字资产，DAO组织表示，WOR2.0 时代每一个协议铸造发行的 WOR 必须有 一定价值的运动数据支持。",
    "Different ways of motion somatosensory wearable devices monitor the combination of meta-universe and offline motion.": "运动方式多样 体感穿戴设备监测 元宇宙与线下运动结合",
    "AUTOMATIC COMPOUND INCOME": "自動複合收益",
    "Fixed APY12846.92%, each token holder automatically gets 0.00007% interest every 3 seconds, just hold $WOR tokens in his wallet!": "固定 APY12846.92%，每個代幣持有者每 3秒自動獲得 0.00007% 的利息，只需在自己的錢包中持有 $WOR 代幣",
    "BLACK HOLE": "",
    "2% of the $WOR sold automatically goes into the black hole and is destroyed!": "卖出交易的 $WOR 中有 2% 自動打入黑洞中並且被銷毀！",
    "AUTOMATIC-FLOW CELL": "自動-流動池",
    "Our automatic liquidity engine injects liquidity at regular intervals!": "每隔固定时间我們的自動流動性引擎注入一次流动性！",
    "TREASURY FUND": "国库基金",
    "We have a team of top digital asset investment experts from Wall Street, and the treasury fund will be used to invest in the most potential digital assets! Provide support for the project APY!": "我們擁有來自華爾街頂級的數字資產投資專家團隊，国库基金將用於投資最具潛力的數字資產！為項目APY提供支撐！",
    "DAO Reward users who contribute to the ecology! Help your team go viral!": "DAO奖励用於奖励為生态贡献的用戶！幫助你的團隊病毒式裂變！",
    "SUMMONER'S MEDAL NFT":"召唤者勋章NFT",
    "5% of the selling fee is used for NFT dividends of medals. Note: medals can only be promoted!":"卖出手续费的5%用於勳章NFT分紅。注意：勳章只能推广獲得！",
    "TREASURY FUND AS INSWO ECOLOGICAL": "INSWO 国库生态基金",
    "IMPORTANCE": "",
    "NATIONAL TREASURY": "国库 保险基⾦",
    "3% of all transaction fees are stored in the treasury insurance base.": "所有交易费⽤的 3%存储在国库保险基⾦中",
    "Fortunately, we have a team of top digital asset investment experts from Wall Street. Most of them have been focusing on the investment of digital assets since the advent of Bitcoin in": "很幸運，我們擁有來自華爾街頂級的數字資產投資專家團隊，他們中的大多數人從2009年比特幣問世開始，就一直專注於數字資產的投資，成功的案例數不勝數，實現了高達數万倍的投資收益。",
    "NFT TRADE MARKET": "NFT 交易市场",
    "WOR SUBSCRIPTION": "WOR 认购",
    "LP DYNAMIC": "LP ⾃动添加",
    "That is, restricted reflection protocol-all transaction fees are reflected to the address where WOR tokens are held, and any operation is required to receive the restricted USDT.": "即⽆限⾃动反射协议 - 所有交易费⽤的会⾃动反映到持有 WOR代币的地址，⽆需任何操作即可⾃动接收⽆限制的 USDT",
    "WOR TROUBLE": "WOR 问题解答",
    "SHOOTING": "",
    "WOR PRICE": "WOR 价格",
    "TOTAL CURRENT": "当前总供应量",
    "SUPPLY": "",
    "TOTAL CIRCULATION": "总流通供应",
    "COMMUNITY\nTEAM": "社区团队",
    "TEAM": "团队",
    "Produced by WOR, all rights reserved.": "版权 由 WOR 制作, 保留所有权利",
    "IDO Ends": "IDO结束倒计时",
    "Countdown": "",
    "IDO Price": "IDO价格",
    "DAYS": "天",
    "HOURS": "时",
    "MINUTES": "分",
    "SECONDS": "秒",
    "IDO Total Amount": "IDO 总额",
    "Number of IDO": "IDO 总数",
    "My Subscription": "我的认购",
    "Move your finger, forward it, and witness the power of consensus Maybe one of your retweets is driving a huge increase": "动动手指，一起转发，共同召唤。让更多WOR DAO组织成员见证召唤者联盟共识的力量，也许你的一条推文转发，将带来巨大的流量增长。",
    "Website": "网站",
    "2022#Dark HorsePotential Proiect#Block Forest ecoloay is comina. Block Forest. an agaregation platform built on the #Bsc public chain, will give you another hundred times the opportunity. Unique mechanism of mutual wealth! On May 22, PANCAKE will be launched soon.": "2022年真DEFI金融，WEB3.0生态落地应用，WOR DAO～一个建立在BSC公链上的聚合平台，独创五大机制暴力分红交易手续费（铸造协议发行机制、免质押复利挖矿机制、IDO打散机制、万分之一分红共识机制、NFT卡牌分红机制）～",
    "2022-WOR DAO-": "【WOR DAO真诚邀您共同召唤·一起共识布道】",
    "Contract address": "合约地址",
    "Copy": "复制",
    "Claim": "领取",
    "Basic IDO Quota": "IDO 基础配额",
    "Please Wait": "请等待",
    "Expected": "预估",
    "to get 10000 WOR": "得到 10000 WOR",
    "WOR-DAO Partner IDO Quota": "WOR-DAO 合伙人 IDO 配额",
    "Join WOR-DAO Partner": "加入 WOR-DAO 合伙人",
    "Total team": "全部团队",
    "members": "成员",
    "purchases": "购买",
    "reward": "奖励",
    "Receiving address": "接收地址",
    "Receiving": "可领取",
    "Address": "地址",
    "Purchase": "购买",
    "Reward": "奖励",
    "Tips": "提示",
    "Copy Success": "复制成功",
    "Copy Failed": "复制官方",
    "WOR Waiting for Release": "待释放 WOR",
    "WOR Available": "可领取 WOR",
    "Round 1": "第一轮",
    "Round 2": "第二轮",
    "Round 3": "第三轮",
    "One wallet limit 100 USDT": "每个钱包只能认购100USDT",
    "Rule": "规则",
    "NFT Rule": "NFT领取细则",
    "Invite 10 partners into IDO can be successful to receive a NFT, invited 20 to take 2, 30 to take 3, and so on, get the address of NFT can obtain a total of 200USDT lines": "成功直接邀请10位伙伴参与IDO，即可免费获取NFT卡牌一张，邀请20位可获取2张，以此类推。获取NFT卡牌的地址可获得WOR总计200USDT的认购权。",
    "1. How to make dynamic pledge?": "1. 自动质押如何工作？",
    "Auto-Stake function is a simple and cutting-edge function, called Buy-Hold-Earn, which is provided for $WOR holders.": `Auto-Stake 功能是一个简单而前沿的功能，称为Buy-Hold-Earn ，它为 $WOR 持有者提供了终极易用性。Buy-Hold-Earn - 只需在您的钱包中购买并持有 $WOR 代币，您就可以获得 rebase 奖励作为利息直接存入您的钱包，每1个区块，你的代币就会增加

使用 Positive Rebase 公式，WOR 使得代币分配的支付与 epoch rebase 奖励成正比成为可能，增长 0.00007% 对于您钱包中持有的 $WOR 代币总量的每 1 个区块周期。rebase 奖励在每个 EPOCH（1 个区块 rebase 周期）分配给所有 $WOR 持有者。这意味着，在不将代币从钱包中移出的情况下，代币持有者最高可获得1,284,609.98% （第 1 个月）此后下降到稳定的APY
    `,
    "2. How to do dynamic reflex?": "2. 自动反射如何工作？",
    "Automatic reflection USDT: The automatic reflection function is a simple and unique feature that does not require any pledge or locking position. The reflection protocol automatically enables USDT reflection for wallet addresses with $WOR≥1‰. The more $WOR is held, the more USDT is reflected. Simply holding WOR-reflection USDT increases the value of $WOR": `自动反射 USDT: 自动反射功能是一个简单而独特的功能，不需要任何质押或锁定位置。反射协议自动为$WOR≥1‰的钱包地址开启USDT反射，持有的$WOR越多，反射的USDT相对越多，简单的持有WOR-反射USDT，不断推高$WOR的价值

Buy-Hold-Earn - 只需在钱包中持有 $WOR 代币，无需任何操作，您就可以直接在钱包中赚取 USDT 奖励作为反射奖励，没有数量或时间限制，并且您自动获得 USDT 奖励，只要有一笔交易，就会自动开启 WOR 自动反射协议，代币分配与交易的次数成正比，WOR 每次交易触发反射协议，自动将 USDT 反射到 $WOR 持有的钱包中
    
这意味着在不从钱包中取出代币的情况下，$WOR 代币持有者每时每刻都能获得 USDT 反射奖励，交易的频率和数量越高，反射的频率和数量就越高。`,
    "3. Sports burning and destruction": "3.运动燃烧销毁",
    "2% of The $WOR sold was burned in The Fire Pit\n\nThe more transactions, the more they burn, and become bigger and bigger by self-fulfilling automatic compounding, reducing the circular supply and keeping the motion protocol stable\n\nAnother benefit of the circular supply perpetual burning is that, due to its deflationary nature, it equates to a higher value per $WOR token, thus increasing individual value\n\nBurning destruction Address:\n0x0000000000000000000000000000000000000000\n0x000000000000000000000000000000000000dead": `2 % 卖出交易的 $WOR 都在The Fire Pit中烧毁

交易越多，燃烧的越多，通过自我实现的自动复合，减少循环供应并保持运动协议稳定，从而变得越来越大
    
循环供应永久燃烧的另一个好处是，由于它的通缩性质，相当于每个 $WOR 代币的价值更高，因此增加了个人价值
    
燃烧销毁地址：
0x0000000000000000000000000000000000000000
0x000000000000000000000000000000000000dead`,
    "4. Treasury insurance base": "4.国库保险基金",
    "The Treasury plays a very important role in the agreement. It provides extremely critical functionality to support the growth and sustainability of tokens\n\nThis additional support becomes important if the $WOR token price drops significantly or if there is an unforeseen black swan event. It helps establish a floor for the $WOR token. The Treasury can also be used to fund new Workout products, Metaverse, SocialFi, services and programs that will expand and provide more value to the WOR community, as well as finance marketing": `国库在协议中起着非常重要的作用。它为代币的成长和可持续性提供了极其关键的功能，支持发挥作用

如果 $WOR 代币价格大幅下跌或出现不可预见的黑天鹅事件，这种额外的支持会变得很重要。它有助于建立 $WOR 代币的底价。该国库还可用于资助新的 workout 产品、metaverse、SocialFi、服务和项目，这些项目将扩展并为 WOR 社区提供更多价值，并为营销提供资金`,
    "5. Sports Agreement Insurance Base (IWIF)": "5.运动协议保险基金 (IWIF)",
    "WIF, an acronym for Inspire Workout Insurance Fund, is a standalone wallet within the ASPR system of the Workout Protocol. WPIF uses an algorithm that supports Rebase rewards and is supported by a portion of the buy and sell transaction fees generated in the WPIF wallet. In simple terms, each block allocates a pledge reward (Rebase reward) of 0.0007% supported by the WPIF parameter. This ensures a high and stable interest rate for $WOR token holders\n\n3% of all transaction fees are stored in the Treasury Insurance Fund, which helps to maintain and support the pledge incentive offered by the positive base.\nIWIF guarantees the safety of the holder by:\nAvoid flash crashes by stabilizing prices\nAchieve long-term sustainability and future growth of the sport agreement\nSignificantly reduce downside risks\n\nTreasury Insurance Fund WPIF address: follow the official Twitter"
    : `WIF是 Inspire Workout Insurance Fund 的首字母缩写词，它是 Workout Protocol 的ASPR系统中的一个独立钱包。WPIF 使用支持 Rebase 奖励的算法，并由 WPIF 钱包中产生的部分买卖交易费用支持，简单来说，每 1 个区块分配 0.0007% 的质押奖励（rebase 奖励）由 WPIF 参数支持，从而确保 $WOR 代币持有者获得高且稳定的利率

所有交易费用的 3% 存储在国库保险基金中，这有助于维持和支持正基数提供的质押奖励。
IWIF通过以下方式保证持有人的安全：
通过价格稳定避免闪崩
实现运动协议的长期可持续性和未来增长
大幅降低下行风险
    
国库保险基金 WPIF地址：关注官方twitter`,
    "6. Interest period": "6.长期利息周期",
    "Long Term Interest Cycle (LIC) To maintain sustainability and future growth, we introduced a long term interest cycle (LIC) component that will grant token holders who take at least 3.5 years to reach maximum supply and receive perpetual continuous compound interest, one block every 3 seconds, called EPOCH, There are 10,512,000 epochs in a year.": `长期利息周期（LIC）为了保持可持续性和未来增长，我们引入了一个长期利息周期 (LIC) 组件，该组件将授予至少 3.5 年才能达到最大供应量的代币持有者，并获得永久的持续复利，每 3 秒一个块，称为EPOCH（纪元） ，1 年有 10,512,000 个 EPOCH（纪元）

纪元增长率：
EPOCH 001-864,000：每个 EPOCH 0.00007%（第一个月）
EPOCH 864,001-1,728,000：每个 EPOCH 0.000011%（第二个月）
EPOCH 1,728,001-2,592,000：每个 EPOCH 0.0000011%（第三个月）
EPOCH 2,592,001-3,456,000 ：每个 EPOCH 0.0000002%（第四个月开始 一直在增长复利，直到达到最大供应量210亿） `,
    "7. Dynamic liquidity engine": "7. 自动流动性引擎",
    "Add liquidity engine (automatic LP)\nLP market liquidity is crucial; Plays a crucial role in buying and selling $WOR tokens on PancakeSwap\nIn layman's terms, think of liquidity as a large amount of money divided 50/50 between $WOR tokens and $USDT tokens. There is a conversion ratio set to the amount of $WOR you can get through USDT\n\nWhen someone buys WOR, the price of each WOR token goes up and the ratio changes at the same time to illustrate this point. The same is true in the opposite direction of selling\n\nLiquidity allows anyone to buy and sell their WOR/USDT at any time, but the less money/liquidity there is in the pool, the worse the price you get, so what our Motion Protocol Automatic Liquidity Engine (WALE) does is add more liquidity to the pool itself and therefore solve the problem\n\nHere's how the Motion Protocol Automatic Mobility Engine (WALE) works:\nFor every 10,000 WORS, the Automatic Liquidity engine (WALE) will automatically inject liquidity into the market. On each buy and sell order, taxes are automatically stored in the Auto-LP wallet and built into the smart contract we have agreed upon, which cleverly extracts 50% of the amount of WOR stored in the wallet and will automatically purchase the USDT at the current market price.\nThe remaining 50% WOR in the Auto-LP wallet will be used for liquidity on the WOR side, thus giving equal 50/50 weight to WOR/USDT, which will then be automatically added to the market pair as new additional liquidity and increase liquidity in the pool\n\nWALE will do this by adding more and more liquidity to the pool for every 10,000 WORS, which will allow $WOR token holders to easily sell their tokens at any time with few market slippage points. It will also help maintain the stability of the protocol to ensure that APY is maintained throughout the life of WOR"
        : "添加流动性引擎（自动LP）\nLP市场流动性至关重要；在 PancakeSwap 上买卖 $WOR 代币方面发挥着至关重要的作用\n通俗地说，将流动性视为一大笔资金，在 $WOR 代币与 $USDT 代币之间以 50/50 的比例分配。有一个转换比率设置为您可以通过 USDT 获得的 $WOR 数量 \n\n当有人购买 WOR 时，每个WOR代币 的价格会上涨，而比率也会同时发生变化，以说明这一点。卖出的相反方向也是如此\n\n流动性允许任何人随时买卖他们的 WOR/USDT，但是池中的资金/流动性越少，你得到的价格就越差，所以我们的运动协议自动流动性引擎 (WALE) 所做的就是增加更多流动性该池本身并因此解决了该问题\n以下是运动协议自动流动性引擎 (WALE) 的工作原理：\n每 10000 个 WOR，自动流动性引擎 (WALE) 将自动向市场注入流动性。在每个买卖订单上，都会有税费自动存储到 Auto-LP 钱包中，并内置在我们协议的智能合约中，该机制巧妙地提取了钱包中存储的 WOR 数量的 50%，并且将自动以当前市场价格购买 USDT。\nAuto-LP 钱包中剩余的 50% WOR 将用于 WOR 方面的流动性，因此赋予 WOR/USDT 相等的 50/50 权重，然后将作为新的额外流动性自动添加到市场对中，并且增加池中的流动性\n\nWALE 将通过向池中增加越来越多的流动性，每 10000 个 WOR执行此操作，这将允许 $WOR 代币持有者随时轻松出售他们的代币，几乎没有市场滑点。它还将有助于维持协议的稳定性，以确保 APY 在 WOR 的整个生命周期内得到维护",
    "8. IDO marketing case": "8. IDO营销方案",
    "Share 10 people successfully IDO can get a NFT, additional 200USDT WOR token, free NFT enjoy permanent dividend, successfully invited 20 people can get 2 pieces and so on.": "分享10人成功进行IDO可获得NFT一张，额外获得200usdt WOR代币额度，免费获得NFT享受永久分红，成功邀请20人可获得2张以此类推。",
    "9. Invitation and reward": "9. 邀请奖励",
    "In the future, for the stable growth and development of the whole movement agreement, the holders who make contributions to the whole agreement will be rewarded with additional dividends and hold the wallet invitation of $WOR≥1/10000\n\nIf you hold a wallet with $WOR≥1/10000, you will get the bonus of usDT. The maximum bonus proportion is 50% of the reflection bonus proportion. At most, you can link to the secondary wallet and get the bonus USDT proportion of 60% and 40%": `未来为了整个运动协议的稳定成长和发展，对整个协议做出贡献的持有人进行额外分红奖励，持有$WOR≥1/10000的钱包邀请
持有$WOR≥1/10000的钱包，额外获得分红usdt的奖励，奖励比例最高占反射分红比例的50%，最多可链接二级钱包，获得分红usdt比例的 60％和40％`,
    "10. metaverse workout club": "10. 元宇宙运动俱乐部",
    "Virtual sports space, combined with a variety of physical chip sensor equipment, gravity sensor, sports bracelet sensor, height sensor, temperature sensor, etc., can upload healthy sports video, healthy life sharing space, can be social, can be rented, online operation is open to users, can obtain $WOR token.": "虚拟运动空间，结合多种物理芯片传感器设备，重力传感器，运动手环传感器，高度传感器，温度传感器等，进行健康运动视频上传，健康生活分享空间，可进行社交，可租赁，可线上运营对用户开放，可获取$WOR 代币。",
    "Referral Link": "邀请链接",
    "Total team\nInvite": "直推人员",
    "Total team\nmembers": "团队人数",
    "Total team\rhold": "团队持币",
    "to get": "得到",
    "Total Current\n Supply": "当前总供应量",
    "Total Supply": "总流通供应",
    "Total Burn": "总销毁量",
    "Holders": "万一持币地址数",
    "Total USDT \n dividend": "分红USDT数",
    "Referrer": "邀请人地址",
    "Bind": "绑定",
    "Deposit": "购买",
    "Not online yet！": "暂未开放！",
    "Info": "数据",
    "Market Cap": "市值",
    "Burn Cap": "总销毁市值",
    "Burn Rate": "销毁占比",
    "Pancake Liquidity": "支持流动性",
    "Better Hold": "最佳持币数量",
}
