<template>
  <div class="text-white w-full min-h-screen" id="home">
    <div class="relative">
      <img src="../../assets/images/1.png" width="100%" height="auto" alt="">
      <div class="absolute text-left" style="top: 12%; left: 5%">
        <div class="font-bold leading-none" style="
        background: linear-gradient(0deg, #1E84E5, #7CD2FB);
        -webkit-background-clip: text;
        color: transparent;" :style="[{fontSize: fixScale(144, 0)}]">{{$t('Inspire')}}</div>
        <div class="font-bold leading-none" style="" :style="[{fontSize: fixScale(144, 0)}]">{{$t('Workout')}}</div>
        <div class="font-normal leading-none" style="" :style="[{fontSize: fixScale(99, 0)}]">{{$t('')}}INs.Wo</div>
        <div class="font-normal leading-tight" :style="[{
          fontSize: fixScale(35, 0),
          marginTop: fixScale(28, 0)
        }]">{{$t('WORKOUT PROTOCOL')}} &</div>
        <div class="font-normal leading-tight" style="" :style="[{fontSize: fixScale(35, 0)}]">{{$t('SPORTS AGREEMENT')}}</div>
        <div class="flex flex-row" :style="[{
          marginTop: fixScale(20, 10)
        }]">
          <div @click="toRoute('/ido')" class="font-bold rounded-full text-center flex flex-col justify-center" style="
          min-width: 80px;
          min-height: 26px;
          background: linear-gradient(0deg, #1E84E5, #7CD2FB)" :style="[{
            fontSize: fixScale(30, 10),
            width: fixScale(280, 0),
            height: fixScale(80, 0),
          }]">IDO</div>
          <div @click="nftTradeTap" class="font-bold rounded-full text-center flex flex-col justify-center" style="
          background-color: transparent;
          border: 2px solid #1E84E5;
          min-width: 100px;
          min-height: 26px;
          " :style="[{
            fontSize: fixScale(30, 10),
            width: fixScale(380, 0),
            height: fixScale(80, 0),
            marginLeft: fixScale(20, 0)
          }]">{{$t('NFT TRADE')}}</div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap justify-between mt-10" :style="[{
      paddingLeft: fixScale(107, 0),
      paddingRight: fixScale(71, 0)
    }]">
      <div>
        <div class="font-bold" :style="[{
          fontSize: fixScale(46, 0)
        }]">{{$t('1')}}{{$t('STAGE')}}:</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(36, 8)
        }]">{{$t('buy-hold-earn')}}</div>
        <div  class="font-normal" :style="[{
          fontSize: fixScale(36, 8)
        }]">{{$t('Buy+hold+make money')}};</div>
      </div>
      <div class="flex flex-row">
        <div class="h-full" style="width: 2px; background-color: #FFFFFF59" :style="[{
          marginRight: fixScale(90, 0)
        }]"/>
        <div>
          <div class="font-bold" :style="[{
          fontSize: fixScale(46, 0)
        }]">{{$t('2')}}{{$t('STAGE')}}:</div>
          <div class="font-normal" :style="[{
          fontSize: fixScale(36, 8)
        }]">{{$t('workout-to-earn')}}</div>
          <div class="font-normal" :style="[{
          fontSize: fixScale(36, 8)
        }]">{{$t('Exercise makes money')}};</div>
        </div>
      </div>
<!--      <div class="h-full" style="width: 2px; background-color: #FFFFFF59"/>-->
      <div class="flex flex-row">
        <div class="h-full" style="width: 2px; background-color: #FFFFFF59" :style="[{
          marginRight: fixScale(90, 0)
        }]"/>
        <div>
          <div class="font-bold" :style="[{
          fontSize: fixScale(46, 0)
        }]">{{$t('3')}}{{$t('STAGE')}}:</div>
          <div class="font-normal whitespace-pre" :style="[{
          fontSize: fixScale(36, 8)

        }]">{{$t('Smart wearable devices+\nmeta-space+sports \nto make money.')}}</div>

        </div>
      </div>
    </div>
    <div class="mt-10">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        {{$t('WOR -')}}
        <span class="text-white">{{$t('NINE ADVANTAGES')}}</span>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center mt-10">
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/1.png" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize: fixScale(24, 8)
        }]">{{$t('AGREEMENT\nCASTING AND\nISSUING')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/2.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize: fixScale(24, 8)
        }]">{{$t('PLEDGE-FREE\nCOMPOUND\nINTEREST\nDOUBLE DIVIDEND')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/3.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('TRANSACTION\nDESTRUCTION')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/4.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('ADDRESS\n SCATTERING')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/5.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('COMMUNITY\n AUTONOMY')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/6.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('CHIBI\n COMPOUND \nINTEREST')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/7.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('WEB3.0 \nECOLOGICAL\n LANDING')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/8.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('IN CASE \nOF \nCHIBI \nPROJECT')}}</div>
      </div>
      <div class="flex flex-col items-center mb-2" style="width: 26%; max-width: 180px">
        <img class="w-12 md:w-24" src="../../assets/images/1/9.png" width="90px" height="auto" alt="">
        <div class="whitespace-pre mt-2 break-all" :style="[{
          fontSize:fixScale(24, 8)
        }]">{{$t('NFT DIVIDEND \nMECHANISM \nAND ASSET \nTRADING \nPLATFORM')}}</div>
      </div>
    </div>
    <div class="mt-10">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        {{$t('WORKOUT PROTOCOL')}}
        <span class="text-white">{{$t('SPORTS AGREEMENT')}}</span>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
    </div>
    <div class="flex items-center justify-center rowCol mt-10" :style="[{
      paddingLeft: fixScale(144, 0),
      paddingRight: fixScale(95, 0)
    }]">
      <img class="mt-5" src="../../assets/images/2.png" :style="[{
        width: fixScale(697, 300),
        height: fixScale(525, 225),
        marginRight: fixScale(152, 20),
      }]">
      <div class="mt-5 text-left" id="about">
        <div class="font-bold" :style="[{
          fontSize: fixScale(52, 13),
        }]">{{$t('ABOUT INSWO')}}</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(28, 10),
          lineHeight: fixScale(36, 12)
        }]">{{$t(`Inspire Workout It aims at engaging in all kinds of sports from
          motivated participants through the third generation Internet
          Web3.0, Blockchain technology, Token economy, and a variety
          of intelligent physical somatosensory monitoring wearable
          devices. Combining NFT, DeFi, Social-Fi, Metaverse, Game-Fi,
          Dao and other organizations should be expanded. Specifically,
          even if smart phones and various fitness wearable devices track
          data, physical sensors such as GPS position, pressure,
          temperature, humidity, temperature, rate, etc. track all kinds of
          data about the surrounding environment and body functions,
          which reflects the wearer's exercise style. By working out to earn
          token, we can create the concept of minimal fitness, and want
          to do fitness anytime and anywhere, so that exercise becomes
          more convenient, and make health melt, keep breathing and
          exercise.`)}}</div>
      </div>
    </div>

    <div class="flex items-center justify-center rowCol mt-10" :style="[{
      paddingLeft: fixScale(144, 0),
      paddingRight: fixScale(95, 0)
    }]">
      <div class="mt-5 text-left">
        <div class="font-bold" :style="[{
          fontSize: fixScale(52, 13),
        }]">{{$t('INSWO PROMOTING HEALTHY SPORTS DEVELOP')}}</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(30, 11),
          lineHeight: fixScale(46, 12)
        }]">{{$t(`Aiming at designing a digital asset linked to sports health data,
            DAO organization said that every WOR cast and issued by
        agreement in WOR2.0 era must be supported by sports data
            of certain value.`)}}</div>
        <div class="font-normal leading-5 mt-5" :style="[{
          fontSize: fixScale(30, 11)
        }]">{{$t(`Different ways of motion somatosensory wearable devices
            monitor the combination of meta-universe and offline motion.`)}}</div>
      </div>
      <img class="mt-5" src="../../assets/images/3.png" :style="[{
        width: fixScale(876, 300),
        height: fixScale(687, 236),
        marginRight: fixScale(152, 20),
      }]">
    </div>

    <div class="mt-5 flex flex-wrap mx-3 justify-center">
      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/1.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t('AUTOMATIC COMPOUND INCOME')}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`Fixed APY12846.92%, each
            token holder automatically gets
            0.00007% interest every
            3 seconds, just hold $WOR
            tokens in his wallet!`)}}</div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/2.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t('BLACK HOLE')}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`2% of the $WOR sold
            automatically goes into the
            black hole and is destroyed!`)}}</div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/3.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t('AUTOMATIC-FLOW CELL')}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`Our automatic liquidity engine
            injects liquidity at regular
            intervals!`)}}</div>
        </div>
      </div>

      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/4.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t('TREASURY FUND')}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`We have a team of top digital
            asset investment experts from
            Wall Street, and the treasury
            fund will be used to invest in the
            most potential digital assets!
            Provide support for the project
            APY!`)}}</div>
        </div>
      </div>

      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/5.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t('DAO')}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`DAO Reward users who
            contribute to the ecology!
            Help your team go viral!`)}}</div>
        </div>
      </div>

      <div class="flex flex-row items-center justify-center mx-3 mt-2 rounded-xl" style="minWidth: 110px; background-color: #2F2F2F" :style="[{
        width: fixScale(520, 130),
        paddingLeft: fixScale(24, 5),
        paddingRight: fixScale(24, 5),
        paddingTop: fixScale(40, 10),
        paddingBottom: fixScale(50, 12)
      }]">
        <img src="../../assets/images/2/6.png" alt="" :style="[{
          width: fixScale(125, 30),
          height: fixScale(145, 34)
        }]">
        <div class="ml-2">
          <div class="font-bold" :style="[{
            fontSize: fixScale(24, 7)
          }]">{{$t(`SUMMONER'S MEDAL NFT`)}}</div>
          <div class="font-normal" :style="[{
            fontSize: fixScale(20, 5)
          }]">{{$t(`5% of the selling fee is used
            for NFT dividends of medals.
            Note: medals can only be
            promoted!`)}}</div>
        </div>
      </div>

    </div>

    <div class="mt-10" id="develop">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        <div class="text-white">{{$t(`TREASURY FUND AS INSWO ECOLOGICAL`)}}</div>
        <div>{{$t('IMPORTANCE')}}</div>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
    </div>

    <div class="flex flex-wrap justify-center mt-10">
      <div class="mt-2 flex flex-col items-center rounded-xl mx-2 py-2 md:py-5" style="background-color: #2F2F2F;" :style="[{
        width: fixScale(320, 120)
      }]">
        <img src="../../assets/images/3/1.png" alt="" :style="[{
          width: fixScale(72, 26),
          height: fixScale(72, 26)
        }]">
      </div>
      <div class="mt-2 flex flex-col items-center rounded-xl mx-2 py-2 md:py-5" style="background-color: #2F2F2F;" :style="[{
        width: fixScale(320, 120)
      }]">
        <img src="../../assets/images/3/2.png" alt="" :style="[{
          width: fixScale(72, 26),
          height: fixScale(72, 26)
        }]">
      </div>
      <div class="mt-2 flex flex-col items-center justify-center rounded-xl mx-2 py-2 md:py-5" style="background-color: #2F2F2F;" :style="[{
        width: fixScale(320, 120)
      }]">
        <img src="../../assets/images/3/3.png" alt="" :style="[{
          width: fixScale(122, 43),
          height: fixScale(45, 16)
        }]">
      </div>
      <div class="mt-2 flex flex-col items-center rounded-xl mx-2 py-2 md:py-5" style="background-color: #2F2F2F;" :style="[{
        width: fixScale(320, 120)
      }]">
        <img src="../../assets/images/3/4.png" alt="" :style="[{
          width: fixScale(72, 26),
          height: fixScale(72, 26)
        }]">
      </div>
    </div>
    <div class="mx-10 rounded-xl mt-5" style="max-width: 1520px; height: auto;">
      <img src="../../assets/images/code.png" width="100%" height="auto" alt="">
    </div>
    <div class="mt-10">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        <div>{{$t('NATIONAL TREASURY')}}
          <span class="text-white"> {{$t('')}}INSURANCE BASE</span>
        </div>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
      <div class="mt-5" :style="[{
        fontSize: fixScale(42, 12)
      }]">{{$t('3% of all transaction fees are stored in the treasury insurance base.')}}</div>
    </div>
    <div class="flex items-center justify-center rowCol mt-10" :style="[{
      paddingLeft: fixScale(144, 0),
      paddingRight: fixScale(95, 0)
    }]">
      <img class="mt-5" src="../../assets/images/4.png" :style="[{
        width: fixScale(761, 300),
        height: fixScale(542, 213),
        marginRight: fixScale(152, 20),
      }]">
      <div class="mt-5 text-left">

        <div class="font-normal leading-4" :style="[{
          fontSize: fixScale(30, 12),
          lineHeight: fixScale(46, 12)
        }]">{{$t(`Fortunately, we have a team of top digital asset investment
          experts from Wall Street. Most of them have been focusing on
          the investment of digital assets since the advent of Bitcoin in`)}}
        </div>
        <div class="my-8" style="background-color: #4D9EF2; height: 3px" :style="[{
          width: fixScale(224, 80)
        }]"/>
        <div class="flex flex-row">
          <div class="font-bold rounded-full text-center flex flex-col justify-center" style="
          background: linear-gradient(0deg, #1E84E5, #7CD2FB)" :style="[{
            fontSize: fixScale(30, 10),
            width: fixScale(431, 140),
            height: fixScale(70, 36),
          }]">{{$t('NFT TRADE MARKET')}}</div>
          <div class="font-bold rounded-full text-center flex flex-col justify-center" style="
          /*background: linear-gradient(0deg, #1E84E5, #7CD2FB);*/
          background-color: transparent;
          border: 2px solid #1E84E5;
          " :style="[{
            fontSize: fixScale(30, 10),
            width: fixScale(431, 120),
            height: fixScale(70, 36),
            marginLeft: fixScale(20, 0)
          }]">{{$t('WOR SUBSCRIPTION')}}</div>
        </div>
      </div>
    </div>
<!--    <div class="mx-auto rounded-xl mt-10" style="max-width: 1729px; background-color: #2F2F2F">-->
<!--      <div class="font-bold pt-4" :style="[{-->
<!--        fontSize: fixScale(60, 14)-->
<!--      }]">Interest rate calculator</div>-->
<!--      <div class="w-full" :style="[{-->
<!--        paddingLeft: fixScale(109, 5),-->
<!--        paddingRight: fixScale(100, 5)-->
<!--      }]">-->
<!--        <div class="w-full flex flex-row justify-between pt-4">-->
<!--          <div class="font-bold" :style="[{-->
<!--          fontSize: fixScale(60, 14)-->
<!--          }]">User holding</div>-->
<!--          <div class="inline-flex flex-row items-center">-->
<!--            <img  src="../../assets/images/coin.png" :style="[{-->
<!--              width: fixScale(61, 20),-->
<!--              height: fixScale(61, 20)-->
<!--            }]">-->
<!--            <div class="ml-1 font-bold" :style="[{-->
<!--          fontSize: fixScale(60, 14)-->
<!--          }]">1311WOR</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="font-bold pt-4" :style="[{-->
<!--        fontSize: fixScale(60, 14)-->
<!--      }]">Get the most</div>-->
<!--      <div class="font-medium pt-4" :style="[{-->
<!--        fontSize: fixScale(32, 10)-->
<!--      }]"><span class="text-main">*</span>Estimate according to precise calculation</div>-->
<!--    </div>-->

    <div class="mt-10">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        <div>{{$t('LP DYNAMIC')}}
          <span class="text-white"> {{$t('ADDITION')}}</span>
        </div>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
      <div class="mt-5 mx-3" :style="[{
        fontSize: fixScale(42, 12)
      }]">{{$t(`That is, restricted reflection protocol-all transaction fees are reflected to the
        address where WOR tokens are held, and any operation is required to
        receive the restricted USDT.`)}}</div>
      <img class="mx-auto mt-3" src="../../assets/images/5.png" :style="[{
      width: fixScale(1005, 300),
      height: fixScale(196, 59)
    }]">
    </div>
    <div class="mt-10">
      <img class="px-3" src="../../assets/images/6.png" width="100%" height="auto" alt="" style="max-width: 1719px">
    </div>

    <div class="mt-10" id="question">
      <div class="font-bold" style="color: #4D9EF2" :style="[{
        fontSize: fixScale(60, 0)
      }]">
        <div>{{$t('WOR TROUBLE')}}
          <span class="text-white"> {{$t('SHOOTING')}}</span>
        </div>
      </div>
      <div class="mx-auto flex flex-row mt-5" style="width: 120px">
        <div class="flex-1" style="background-color: #FFFFFF; height: 2px"></div>
        <div class="flex-1" style="background-color: #4D9EF2; height: 2px"></div>
      </div>
    </div>

    <div class="flex flex-wrap justify-center">
      <div v-for="item in qList" :key="item.id">
        <div @click="qIndex = qIndex == item.id ? null : item.id" class="mt-3 mx-2 rounded-xl flex flex-col justify-center text-left pl-2" style="
        max-width: 880px;
        background: linear-gradient(90deg, #3D3D3D, #2A2A2A);
        " :style="[{
          width: fixScale(880, 320),
          fontSize: fixScale(42, 12),
          height: fixScale(103, 40),
        }]">
          {{$t(item.title)}}
        </div>
        <div v-if="item.desc && qIndex == item.id" class="mt-3 mx-2 text-left pl-2" style="white-space: pre-wrap" :style="[{
          width: fixScale(880, 300),
          fontSize: fixScale(26, 12),
        }]">
          {{$t(item.desc)}}
        </div>
      </div>
    </div>
    
    <div class="w-full flex flex-wrap items-center px-3 mt-10">
      <img src="../../assets/images/4/1.png" style="max-width: 427px" :style="[{
        width: fixScale(427, 60)
      }]">
      <img src="../../assets/images/4/2.png" style="max-width: 427px" :style="[{
        width: fixScale(427, 60)
      }]">
      <img src="../../assets/images/4/3.png" style="max-width: 427px" :style="[{
        width: fixScale(427, 60)
      }]">
      <img src="../../assets/images/4/4.png" style="max-width: 427px" :style="[{
        width: fixScale(427, 60)
      }]">
    </div>
    <div class="flex flex-wrap justify-around px-3 my-10">
      <div>
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">12350+</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(42, 0)
        }]">{{$t('WOR PRICE')}}</div>
      </div>
      <div>
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">2345+</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(42, 0)
        }]">{{$t('TOTAL CURRENT')}}<br/>
          {{$t('SUPPLY')}}</div>
      </div>
      <div>
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">50+</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(42, 0)
        }]">{{$t('TOTAL CIRCULATION')}}<br/>
          {{$t('SUPPLY')}}</div>
      </div>
      <div>
        <div class="font-bold" :style="[{
          fontSize: fixScale(80, 0)
        }]">101+</div>
        <div class="font-normal" :style="[{
          fontSize: fixScale(42, 0),
          whiteSpace: 'pre'
        }]">{{$t(`COMMUNITY\nTEAM`)}}</div>
      </div>
    </div>
    <div class="text-center font-normal py-3" :style="[{
      fontSize: fixScale(24, 6)
    }]">{{$t('Produced by WOR, all rights reserved.')}}</div>
  </div>
</template>

<script>

import WORAbi from "../../assets/abi/WORAbi.json"
import {getContract, isAddress} from "@/utils/contract";
import {log} from "@/utils/tools";
import BigNumber from "bignumber.js";

export default {
  name: "Home",
  computed: {
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    },
    wallet() {
      return this.$store.state.wallet;
    },
    fixPrice() {
      return (p, n, d) => {
        if (d == -1) {
          return new BigNumber(p).div((10**n)+"").toNumber()
        }
        return new BigNumber(p).div((10**n)+"").toFixed(d)
      }
    },

  },
  mounted() {
    // let self = this;
    // window.onresize = () => {
    //   return (() => {
    //     const width = document.body.clientWidth
    //     log(`width = ${width}`)
    //     self.scale = width < 1920 ? width/1920 : 1;
    //   })()
    // }
    // const width = document.body.clientWidth
    // self.scale = width < 1920 ? width/1920 : 1;
    //
    // setTimeout((() => {
    //   const width = document.body.clientWidth
    //   self.scale = width < 1920 ? width/1920 : 1;
    // }, 500))

    // setInterval(()=> {
    //   self.loadValues();
    // }, 6000);
    //
    // setTimeout((() => {
    //   self.loadValues();
    // }, 1000))
  },
  data() {
    return {
      // scale: 1,
      qIndex: null,
      qList: [
        {
          id: 1,
          title: "1. How to make dynamic pledge?",
          desc: "Auto-Stake function is a simple and cutting-edge function, called Buy-Hold-Earn, which is provided for $WOR holders."
        },
        {
          id: 2,
          title: "2. How to do dynamic reflex?",
          desc: "Automatic reflection USDT: The automatic reflection function is a simple and unique feature that does not require any pledge or locking position. The reflection protocol automatically enables USDT reflection for wallet addresses with $WOR≥1‰. The more $WOR is held, the more USDT is reflected. Simply holding WOR-reflection USDT increases the value of $WOR",
        },
        {
          id: 3,
          title: "3. Sports burning and destruction",
          desc: "2% of The $WOR sold was burned in The Fire Pit\n\nThe more transactions, the more they burn, and become bigger and bigger by self-fulfilling automatic compounding, reducing the circular supply and keeping the motion protocol stable\n\nAnother benefit of the circular supply perpetual burning is that, due to its deflationary nature, it equates to a higher value per $WOR token, thus increasing individual value\n\nBurning destruction Address:\n0x0000000000000000000000000000000000000000\n0x000000000000000000000000000000000000dead"
        },
        {
          id: 4,
          title: "4. Treasury insurance base",
          desc: "The Treasury plays a very important role in the agreement. It provides extremely critical functionality to support the growth and sustainability of tokens\n\nThis additional support becomes important if the $WOR token price drops significantly or if there is an unforeseen black swan event. It helps establish a floor for the $WOR token. The Treasury can also be used to fund new Workout products, Metaverse, SocialFi, services and programs that will expand and provide more value to the WOR community, as well as finance marketing",
        },
        {
          id: 5,
          title: "5. Sports Agreement Insurance Base (IWIF)",
          desc: "WIF, an acronym for Inspire Workout Insurance Fund, is a standalone wallet within the ASPR system of the Workout Protocol. WPIF uses an algorithm that supports Rebase rewards and is supported by a portion of the buy and sell transaction fees generated in the WPIF wallet. In simple terms, each block allocates a pledge reward (Rebase reward) of 0.0007% supported by the WPIF parameter. This ensures a high and stable interest rate for $WOR token holders\n\n3% of all transaction fees are stored in the Treasury Insurance Fund, which helps to maintain and support the pledge incentive offered by the positive base.\nIWIF guarantees the safety of the holder by:\nAvoid flash crashes by stabilizing prices\nAchieve long-term sustainability and future growth of the sport agreement\nSignificantly reduce downside risks\n\nTreasury Insurance Fund WPIF address: follow the official Twitter"
        },
        {
          id: 6,
          title: "6. Interest period",
          desc: "Long Term Interest Cycle (LIC) To maintain sustainability and future growth, we introduced a long term interest cycle (LIC) component that will grant token holders who take at least 3.5 years to reach maximum supply and receive perpetual continuous compound interest, one block every 3 seconds, called EPOCH, There are 10,512,000 epochs in a year.",
        },
        {
          id: 7,
          title: "7. Dynamic liquidity engine",
          desc: "Add liquidity engine (automatic LP)\nLP market liquidity is crucial; Plays a crucial role in buying and selling $WOR tokens on PancakeSwap\nIn layman's terms, think of liquidity as a large amount of money divided 50/50 between $WOR tokens and $USDT tokens. There is a conversion ratio set to the amount of $WOR you can get through USDT\n\nWhen someone buys WOR, the price of each WOR token goes up and the ratio changes at the same time to illustrate this point. The same is true in the opposite direction of selling\n\nLiquidity allows anyone to buy and sell their WOR/USDT at any time, but the less money/liquidity there is in the pool, the worse the price you get, so what our Motion Protocol Automatic Liquidity Engine (WALE) does is add more liquidity to the pool itself and therefore solve the problem\n\nHere's how the Motion Protocol Automatic Mobility Engine (WALE) works:\nFor every 10,000 WORS, the Automatic Liquidity engine (WALE) will automatically inject liquidity into the market. On each buy and sell order, taxes are automatically stored in the Auto-LP wallet and built into the smart contract we have agreed upon, which cleverly extracts 50% of the amount of WOR stored in the wallet and will automatically purchase the USDT at the current market price.\nThe remaining 50% WOR in the Auto-LP wallet will be used for liquidity on the WOR side, thus giving equal 50/50 weight to WOR/USDT, which will then be automatically added to the market pair as new additional liquidity and increase liquidity in the pool\n\nWALE will do this by adding more and more liquidity to the pool for every 10,000 WORS, which will allow $WOR token holders to easily sell their tokens at any time with few market slippage points. It will also help maintain the stability of the protocol to ensure that APY is maintained throughout the life of WOR"
        },
        {
          id: 8,
          title: "8. IDO marketing case",
          desc: "Share 10 people successfully IDO can get a NFT, additional 200USDT WOR token, free NFT enjoy permanent dividend, successfully invited 20 people can get 2 pieces and so on."
        },
        {
          id: 9,
          title: "9. Invitation and reward",
          desc: "In the future, for the stable growth and development of the whole movement agreement, the holders who make contributions to the whole agreement will be rewarded with additional dividends and hold the wallet invitation of $WOR≥1/10000\n\nIf you hold a wallet with $WOR≥1/10000, you will get the bonus of usDT. The maximum bonus proportion is 50% of the reflection bonus proportion. At most, you can link to the secondary wallet and get the bonus USDT proportion of 60% and 40%",
        },
        {
          id: 10,
          title: "10. metaverse workout club",
          desc: "Virtual sports space, combined with a variety of physical chip sensor equipment, gravity sensor, sports bracelet sensor, height sensor, temperature sensor, etc., can upload healthy sports video, healthy life sharing space, can be social, can be rented, online operation is open to users, can obtain $WOR token."
        },
      ],
      worInfo: {
        price: 0,
        betterHoldNum: 0,
        totalSupply: 0,
        totalActive: 0,
        shareholders: 0,
        totalBurn: 0,
        totalPairW: 0,
        totalPairU: 0,
        totalUSDT: 0
      }
    }
  },
  methods: {
    toView(_id) {
      document.getElementById(`${_id}`).scrollIntoView();
    },
    toRoute(_path) {
      this.show = false;
      const path = this.$router.currentRoute.path;
      log(`path = ${path}  _path = ${_path}`)
      if (path == _path) return;
      this.$router.push(_path);
    },
    nftTradeTap() {
      this.$notify.warning({
        title: this.$t("Tips"),
        message: this.$t('Not online yet！'),
      })
    },
    async loadValues() {
      if (!this.wallet || !isAddress(this.wallet)) {
        log(this.wallet)
        return
      }
      log(`this.wallet = ${this.wallet}`);
      const wor = process.env.VUE_APP_WOR;
      const worC = getContract(wor, WORAbi);
      let _worInfo = await worC.methods.worInfo().call();
      // _worInfo.price = new BigNumber(_worInfo.price).div(10**10+"").toNumber();
      this.worInfo = _worInfo;

    }
  }
}
</script>

<style scoped>

@media all and (max-width: 768px) {
  /*.fixFlex {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  align-items: center;*/
  /*  justify-items: center;*/
  /*}*/
  .fixFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .wrapCol {
    flex-direction: column;
  }
  .rowCol {
    flex-direction: column;
  }

}
@media all and (min-width: 768px) {
  .fixFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .wrapCol {
    flex-wrap: wrap;
  }
  .rowCol {
    flex-direction: row;
  }
}


</style>