<template>
  <div class="w-full text-white">
    <div class="font-bold mb-5 mt-10" :style="[{
      fontSize: fixScale(80, 20)
    }]"><span style="color: #4D9EF2">{{$t('IDO Ends')}} </span>{{$t('Countdown')}}</div>
    <div class="max-w-min mx-auto">
      <div class="flex flex-row items-center justify-center">
<!--        <div class="box flex flex-wrap items-center justify-center" :style="[{-->
<!--        width: fixScale(240, 60),-->
<!--        height: fixScale(207, 51)-->
<!--      }]">-->
<!--          <div class="font-bold" :style="[{-->
<!--          fontSize: fixScale(100, 20),-->
<!--          fontFamily: 'PingFang SC',-->
<!--        }]">{{ days }}</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <img src="../../assets/images/ido/m.png" :style="[{-->
<!--          width: fixScale(18, 6),-->
<!--          height: fixScale(75, 25),-->
<!--          marginLeft: fixScale(25, 3),-->
<!--          marginRight: fixScale(25, 3)-->
<!--        }]" alt="">-->
<!--        </div>-->
        <div class="box flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
        height: fixScale(207, 51)
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }]">{{ hours }}</div>
        </div>
        <div>
          <img src="../../assets/images/ido/m.png" :style="[{
          width: fixScale(18, 6),
          height: fixScale(75, 25),
          marginLeft: fixScale(25, 3),
          marginRight: fixScale(25, 3)
        }]" alt="">
        </div>
        <div class="box flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
        height: fixScale(207, 51)
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }]">{{ minutes }}</div>
        </div>
        <div>
          <img src="../../assets/images/ido/m.png" :style="[{
          width: fixScale(18, 6),
          height: fixScale(75, 25),
          marginLeft: fixScale(25, 3),
          marginRight: fixScale(25, 3)
        }]" alt="">
        </div>
        <div class="box flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
        height: fixScale(207, 51)
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }]">{{ seconds }}</div>
        </div>
      </div>
      <div class="mt-3 flex flex-row items-center justify-center">
<!--        <div class="flex flex-wrap items-center justify-center" :style="[{-->
<!--        width: fixScale(240, 60),-->
<!--      }]">-->
<!--          <div class="font-bold" :style="[{-->
<!--          fontSize: fixScale(42, 10),-->
<!--        }]">{{$t('DAYS')}}</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div :style="[{-->
<!--          width: fixScale(18, 6),-->
<!--          marginLeft: fixScale(25, 3),-->
<!--          marginRight: fixScale(25, 3)-->
<!--        }]" alt=""/>-->
<!--        </div>-->
        <div class="flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(42, 10),
        }]">{{ $t('HOURS') }}</div>
        </div>
        <div>
          <div :style="[{
          width: fixScale(18, 6),
          marginLeft: fixScale(25, 3),
          marginRight: fixScale(25, 3)
        }]" alt=""/>
        </div>
        <div class="flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(42, 10),
        }]">{{$t('MINUTES')}}</div>
        </div>
        <div>
          <div :style="[{
          width: fixScale(18, 6),
          marginLeft: fixScale(25, 3),
          marginRight: fixScale(25, 3)
        }]" alt=""/>
        </div>
        <div class="flex flex-wrap items-center justify-center" :style="[{
        width: fixScale(240, 60),
      }]">
          <div class="font-bold" :style="[{
          fontSize: fixScale(42, 10),
        }]">{{$t('SECONDS')}}</div>
        </div>
      </div>
      <div class="w-full mt-8 flex flex-row justify-between" :style="[{
        fontSize: fixScale(70, 20)
      }]">
        <div class="font-bold">{{fixPrice(idoInfo.totalUsdt, 18, 0)}} <span :style="[{
        fontSize: fixScale(40, 12)
      }]">USDT</span></div>
        <div>{{idoInfo.buyersLength}}</div>
      </div>
      <div class="w-full flex flex-row justify-between font-medium" :style="[{
        fontSize: fixScale(40, 12)
      }]">
        <div>{{$t('IDO Total Amount')}}</div>
        <div>{{$t('Number of IDO')}}</div>
      </div>
    </div>
    <div class="font-medium mt-5" :style="[{
      fontSize: fixScale(40, 12)
    }]">{{$t('IDO Price')}}: 1 WOR = {{price}} USDT</div>
    <div class="max-w-min mx-auto">
      <div class="w-full flex rowCol items-center justify-between">
        <div class="rounded-xl mt-5 flex flex-col" style="border: 1px solid #FFFFFF33;" :style="[{
        width: fixScale(830, 300),
        height: fixScale(605, 218)
      }]">
          <div class="font-bold flex flex-col justify-center" :style="[{
          fontSize: fixScale(46, 20),
          height: fixScale(138, 40),
        }]">
            <div>{{$t('Basic IDO Quota')}}</div>
          </div>
          <div class="mx-auto" style="width: 80%; height: 1px; background-color: #FFFFFF33"/>
          <div class="font-medium flex-1 flex flex-wrap items-center justify-center" :style="[{
          fontSize: fixScale(42, 20)
        }]">100 USDT {{$t('Expected')}}<br/>{{$t('to get')}} {{getTokens(100, round)}} WOR</div>
          <div @click="deposit" class="mx-auto rounded-xl font-bold flex flex-wrap items-center justify-center" style="background: linear-gradient(0deg, #b0b0b0, #8c8c8c)" :style="[{
          fontSize: fixScale(40, 18),
          marginBottom: fixScale(96, 20),
          width: fixScale(440, 120),
          height: fixScale(100, 40),
          background: roundEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
          disabled: !roundEnabled
        }]">{{roundEnabled ? $t('Deposit') : $t('Please Wait')}}</div>
        </div>
        <div class="rounded-xl mt-5 flex flex-col" style="border: 1px solid #FFFFFF33;" :style="[{
        width: fixScale(830, 300),
        height: fixScale(605, 218)
      }]">
          <div class="font-medium flex flex-col justify-center" :style="[{
          fontSize: fixScale(42, 18),
          height: fixScale(138, 40),
        }]">
            <div>{{$t('WOR-DAO Partner IDO Quota')}}</div>
          </div>
          <div class="mx-auto rounded-xl font-bold flex flex-wrap justify-center items-center" :style="[{
          fontSize: fixScale(40, 16),
          width: fixScale(480, 240),
          height: fixScale(100, 40),
          background: 'linear-gradient(0deg, #1E84E5, #7CD2FB)'
        }]">{{$t('Join WOR-DAO Partner')}}</div>
          <div class="mt-3 mx-auto" style="width: 80%; height: 1px; background-color: #FFFFFF33"/>
          <div class="font-medium flex-1 flex flex-wrap items-center justify-center" :style="[{
          fontSize: fixScale(42, 20)
        }]">200 USDT {{$t('Expected')}}<br/>{{$t('to get')}} {{getTokens(200, 0)}} WOR</div>
          <div  @click="depositPartner" class="mx-auto rounded-xl font-bold flex flex-wrap items-center justify-center" :style="[{
          fontSize: fixScale(40, 18),
          marginBottom: fixScale(38, 10),
          width: fixScale(440, 120),
          height: fixScale(100, 40),
          background: roundEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
          disabled: !roundEnabled
        }]">{{roundEnabled ? $t('Deposit') : $t('Please Wait')}}</div>
        </div>
      </div>
      <div class="mx-auto rounded-xl mt-5 pb-2 mx-2 flex flex-col" style="border: 1px solid #FFFFFF33;" :style="[{
        width: fixScale(1719, 300),
        // height: fixScale(605, 218),
        // minHeight: fixScale(605, 218),
      }]">
        <div class="font-medium flex flex-col justify-center" :style="[{
          fontSize: fixScale(46, 20),
          height: fixScale(138, 40),
        }]">
          <div>{{$t('Rule')}}</div>
        </div>
        <div class="mt-3 w-full px-2">
          <div class="w-full" style="height: 1px; background-color: #FFFFFF33"></div>
        </div>
        <div class="font-medium flex-1 flex flex-wrap text-left mx-5 mt-2" :style="[{
          fontSize: fixScale(42, 20)
        }]">{{$t('Round 1')}}: 1 WOR = 0.19USDT<br/>
          {{$t('Round 2')}}: 1 WOR = 0.20USDT<br/>
          {{$t('Round 3')}}: 1 WOR = 0.21USDT<br/>
          {{$t('One wallet limit 100 USDT')}}<br/>
          <br/>
          {{$t('NFT Rule')}}:<br/>
          {{$t('Invite 10 partners into IDO can be successful to receive a NFT, invited 20 to take 2, 30 to take 3, and so on, get the address of NFT can obtain a total of 200USDT lines')}}
          </div>

      </div>
      <div class="font-bold mt-5" :style="[{
        fontSize: fixScale(46, 20)
      }]">{{$t('My Subscription')}}</div>
      <div class="flex flex-row justify-between">
        <div :style="[{
          fontSize: fixScale(46, 16)
        }]">{{$t('WOR Waiting for Release')}}</div>
        <div :style="[{
          fontSize: fixScale(46, 16)
        }]">{{$t('WOR Available')}}</div>
      </div>
      <div class="flex flex-row justify-between">
        <div :style="[{
          fontSize: fixScale(46, 16)
        }]">{{0.00}}</div>
        <div :style="[{
          fontSize: fixScale(46, 16)
        }]">{{fixPrice(userInfo.canClaimValue, 8, 0)}}</div>
      </div>
      <div @click="claimTap" class="mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl" :style="[{
          fontSize: fixScale(50, 20),
          width: fixScale(440, 100),
          height: fixScale(100, 40),
          background: userInfo.claimEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
        }]">{{$t('Claim')}}</div>
      <div class="mx-auto rounded-xl mt-5 pb-2 mx-2 flex flex-col" style="border: 1px solid #FFFFFF33;" :style="[{
        width: fixScale(1719, 300),
        // height: fixScale(605, 218),
        // minHeight: fixScale(605, 218),
      }]">
        <div class="mx-5 font-medium flex flex-col justify-center" :style="[{
          fontSize: fixScale(46, 20),
          // height: fixScale(138, 40),
        }]">
          <div>{{$t(`Move your finger, forward it, and witness the power of consensus
            Maybe one of your retweets is driving a huge increase`)}}</div>
        </div>
        <div class="mt-3 w-full px-2">
          <div class="w-full" style="height: 1px; background-color: #FFFFFF33"></div>
        </div>
        <div class="flex-1 flex flex-wrap text-center mx-5 mt-2" :style="[{
          fontSize: fixScale(42, 20),
        }]">{{$t(`2022#Dark HorsePotential Proiect#Block Forest ecoloay is comina.
          Block Forest. an agaregation platform built on the #Bsc public chain,
          will give you another hundred times the opportunity. Unique mechanism of
          mutual wealth! On May 22, PANCAKE will be launched soon.`)}}
          <div class="w-full text-center">{{$t('2022-WOR DAO-')}}</div>
          <div class="mt-1 w-full text-center break-all" style="color: #4C9DF0">{{$t('Referral Link')}}: https://www.wordao.app/{{wallet}}</div>
        </div>
        <div @click="copy2" class="mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl" style="background: linear-gradient(0deg, #1E84E5, #7CD2FB) " :style="[{
          fontSize: fixScale(50, 20),
          width: fixScale(440, 100),
          height: fixScale(100, 40)
        }]">{{$t('Copy')}}</div>

        <div class="mt-5 mb-2 font-bold" :style="[{
          fontSize: fixScale(46, 20),
        }]">{{$t('Contract address')}}:</div>

        <div class="mx-auto px-4 py-2 rounded-md max-w-min" :style="[{
          fontSize: fixScale(36, 12),
          background: '#353434'
        }]">{{ contract }}</div>

        <div @click="copy" class="mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl" style="background: linear-gradient(0deg, #1E84E5, #7CD2FB) " :style="[{
          fontSize: fixScale(50, 20),
          width: fixScale(440, 100),
          height: fixScale(100, 40)
        }]">{{$t('Copy')}}</div>

      </div>

      <div style="height: 40px"/>
    </div>
  </div>
</template>

<script>
import {copy, errFormatMsg, log} from "@/utils/tools";
import {getContract, isAddress} from "@/utils/contract";
import IDOAbi from "@/assets/abi/IDOAbi.json";
import ERC20Abi from "@/assets/abi/ERC20Abi.json";
import BigNumber from "bignumber.js";

export default {
  name: "ido",
  computed: {
    // days() {
    //   const _days = 60*60*24;
    //   let result = Math.floor(this.timesClock/_days);
    //   if (result >= 10) {
    //     return result+"";
    //   }
    //   return "0"+result;
    // },
    hours() {
      // const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock/_hours);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    minutes() {
      // const _days = 60*60*24;
      const _hours = 60*60;
      let result = Math.floor(this.timesClock%_hours/60);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    seconds() {
      // const _days = 60*60*24;
      // const _hours = 60*60;
      const _minutes = 60;
      let result = Math.floor(this.timesClock%_minutes);
      if (result >= 10) {
        return result+"";
      }
      return "0"+result;
    },
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    },
    fixPrice() {
      return (p, n, d) => {
        if (d == -1) {
          return new BigNumber(p).div((10**n)+"").toNumber()
        }
        return new BigNumber(p).div((10**n)+"").toFixed(d)
      }
    },
    wallet() {
      return this.$store.state.wallet ?? "";
    },
    price() {
      let _price;
      if (this.round == 0) {
        _price = 0.19;
      } else if (this.round == 1) {
        _price = 0.20;
      } else if (this.round == 2) {
        _price = 0.21;
      }
      return _price;
    },
    getTokens() {
      const self = this;
      return (amount, _round) => {
        return new BigNumber(amount).times(self.roundTokens[_round]+"").div("100").toFixed(0);
      }
    }
  },
  data() {
    return {
      contract: "",
      round: 0,
      roundEnabled: false,
      timesClock: 0,
      roundInfo: {
        round: 0,
        started: 0,
        clock: 0,
      },
      roundTokens: {
        0: 525,
        1: 500,
        2: 475
      },
      userInfo: {
        depositValue: 0,
        haveClaimedValue: 0,
        availableValue: 0,
        canClaimValue: 0,
        claimEnabled: 0,
        nftNum: 0,
        nftClaimedNum: 0,
        currentPrice: 0,
      },
      idoInfo: {
        totalUsdt: 0,
        totalWor: 0,
        buyersLength: 0,
      },
    }
  },
  mounted() {
    const self = this;
    setInterval(()=> {
      self.loadValues();
    }, 6000);

    setTimeout((() => {
      self.loadValues();
    }, 1000))
    this.contract = process.env.VUE_APP_WOR;

    setInterval(() => {
      if (self.roundInfo.clock == 0) return;
      let now = Math.floor(Date.now()/1000);
      if (new BigNumber(self.roundInfo.clock+"").lte(now+"")) {
        self.timesClock = 0;
      } else {
        self.timesClock = Math.floor(new BigNumber(self.roundInfo.clock+"").minus(now+"").toNumber());
      }
    }, 1000);
  },
  methods: {
    async deposit() {
      const self = this;
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(`this.wallet = ${this.wallet}`)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const usdt = process.env.VUE_APP_USDT;

        const idoC = getContract(worIdo, IDOAbi);
        const amount = "100000000000000000000";

        const usdtContract = getContract(usdt, ERC20Abi);
        const approveValue = await usdtContract.methods.allowance(this.wallet, worIdo).call();

        log(amount)
        if (new BigNumber(approveValue).lt(amount)) {
          // const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let result = await usdtContract.methods.approve(worIdo, amount).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$notify({
              title: self.$t('Tips'),
              message: self.$t('Approve USDT Failed'),
            });
            return
          }
        }

        await idoC.methods.deposit(amount).estimateGas({gas: 5000000, from: this.wallet});
        const result = await idoC.methods.deposit(amount).send({
          from: this.wallet
        });
        if (result.status == true) {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Deposit Success'),
          });
        } else {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Deposit Failed'),
          });
        }
      } catch (e) {
        log(e)
        this.$notify({
          title: self.$t('Tips'),
          message: errFormatMsg(e, "Deposit Failed"),
        });
      }
    },
    async depositPartner() {
      const self = this;
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(`this.wallet = ${this.wallet}`)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const usdt = process.env.VUE_APP_USDT;

        const idoC = getContract(worIdo, IDOAbi);
        const amount = "200000000000000000000";

        const usdtContract = getContract(usdt, ERC20Abi);
        const approveValue = await usdtContract.methods.allowance(this.wallet, worIdo).call();

        log(amount)
        if (new BigNumber(approveValue).lt(amount)) {
          // const max = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
          let result = await usdtContract.methods.approve(worIdo, amount).send({
            from: this.wallet
          });
          if (result.status == false) {
            this.$notify({
              title: self.$t('Tips'),
              message: self.$t('Approve USDT Failed'),
            });
            return
          }
        }

        await idoC.methods.depositPartner(amount).estimateGas({gas: 5000000, from: this.wallet});
        const result = await idoC.methods.depositPartner(amount).send({
          from: this.wallet
        });
        if (result.status == true) {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Deposit Success'),
          });
        } else {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Deposit Failed'),
          });
        }
      } catch (e) {
        log(e)
        this.$notify({
          title: self.$t('Tips'),
          message: errFormatMsg(e, "Deposit Failed"),
        });
      }
    },
    async claimTap() {
      const self = this;
      try {
        if (!this.wallet || !isAddress(this.wallet)) {
          log(`this.wallet = ${this.wallet}`)
          return
        }
        const worIdo = process.env.VUE_APP_IDO;
        const idoC = getContract(worIdo, IDOAbi);

        await idoC.methods.claim().estimateGas({gas: 5000000, from: this.wallet});
        const result = await idoC.methods.claim().send({
          from: this.wallet
        });
        if (result.status == true) {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Claim Success'),
          });
        } else {
          this.$notify({
            title: self.$t('Tips'),
            message: self.$t('Claim Failed'),
          });
        }
      } catch (e) {
        log(e)
        this.$notify({
          title: self.$t('Tips'),
          message: errFormatMsg(e, "Claim Failed"),
        });
      }
    },
    copy() {
      copy(this, this.contract);
    },
    copy2() {
      copy(this, this.$t(`2022#Dark HorsePotential Proiect#Block Forest ecoloay is comina. Block Forest. an agaregation platform built on the #Bsc public chain, will give you another hundred times the opportunity. Unique mechanism of mutual wealth! On May 22, PANCAKE will be launched soon.`)
          +`\n`+this.$t('2022-WOR DAO-')
          +`\n\n`+this.$t('Referral Link')
          +`: https://www.wordao.app/${this.wallet}`)
    },
    async loadValues() {

      if (!this.wallet || !isAddress(this.wallet)) {
        log(this.wallet)
        return
      }
      const worIdo = process.env.VUE_APP_IDO;
      const idoC = getContract(worIdo, IDOAbi);
      // this.round = await idoC.methods.round().call();
      // this.roundEnabled = await idoC.methods.stateByRound(this.round).call();

      this.roundInfo = await idoC.methods.roundInfo().call();
      this.roundEnabled = this.roundInfo.started;
      this.round = this.roundInfo.round;
      log(this.roundInfo)

      const _userInfo = await idoC.methods.userInfoOf(this.wallet).call();
      this.nftNum = new BigNumber(_userInfo.nftNum).minus(_userInfo.nftClaimedNum).toFixed(0);
      this.userInfo = _userInfo;

      this.idoInfo = await idoC.methods.idoInfo().call();
    },
  }
}
</script>

<style scoped>
.box {
  background-image: url("../../assets/images/ido/border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media all and (max-width: 768px) {
  /*.fixFlex {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  align-items: center;*/
  /*  justify-items: center;*/
  /*}*/
  .fixFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .wrapCol {
    flex-direction: column;
  }
  .rowCol {
    flex-direction: column;
  }

}
@media all and (min-width: 768px) {
  .fixFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .wrapCol {
    flex-wrap: wrap;
  }
  .rowCol {
    flex-direction: row;
  }
}

</style>