<template>
  <div class="w-full">
    <div>
      <img class="mt-20 mx-auto" src="../../assets/images/nft-head2.png" :style="[{
        width: fixScale(180, 60),
        height: fixScale(180, 60)
      }]">
    </div>

    <div class="mt-10 flex flex-wrap" :style="[{
      paddingLeft: fixScale(100, 20),
      paddingRight: fixScale(100, 20)
    }]">
      <div v-for="item in nftList" :key="item.id+item.name" class="mx-2 rounded-xl text-white" style="background-color: #2F2F2F" :style="[{
        width: fixScale(400, 150),
        height: fixScale(520, 218),
      }]">
        <img :src="item.image" class="rounded-t-xl" style="object-fit: cover" :style="[{
           width: fixScale(400, 150),
           height: fixScale(400, 150),
        }]">
        <div class="text-left ml-3">
          <div class="font-bold" :style="[{
          fontSize: fixScale(32, 16)
        }]">{{item.name}}</div>
          <div :style="[{
          fontSize: fixScale(28, 16)
        }]">#{{item.id}}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getContract, isAddress} from "@/utils/contract";
import {log} from "@/utils/tools";
import NFT1Abi from "@/assets/abi/WORNFTAbi.json";
// import BigNumber from "bignumber.js";

export default {
  name: "index",
  computed: {
    wallet() {
      return this.$store.state.wallet;
    },
    fixWallet() {
      return this.$store.state.fixWallet;
    },
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    }
  },
  mounted() {
    const self = this;
    setInterval(()=> {
      self.loadValues();
    }, 6000);

    setTimeout((() => {
      self.loadValues();
    }, 1000))
  },
  data() {
    return {
      nftList: [
        // {
        //   image: "",
        //   id: 0,
        //   name: ""
        // }
      ]
    }
  },
  methods: {
    async loadValues() {

      if (!this.wallet || !isAddress(this.wallet)) {
        log(this.wallet)
        return
      }
      const worNFT1 = process.env.VUE_APP_NFT1;
      const nft1C = getContract(worNFT1, NFT1Abi);

      const worNFT2 = process.env.VUE_APP_NFT2;
      const nft2C = getContract(worNFT2, NFT1Abi);

      const list = await nft1C.methods.tokenIdsOf(this.wallet, 0, 100).call();
      log(list)
      const nftList = [];
      for (let i = 0; i < list.length; i++) {
        nftList.push({
          image: "https://tokensimage.github.io/images/BSC/WorDAO/NFT/partner.png",
          id: list[i],
          name: "Partner"
        });
      }

      const list2 = await nft2C.methods.tokenIdsOf(this.wallet, 0, 100).call();
      log(list2)
      const nftList2 = [];
      for (let i = 0; i < list2.length; i++) {
        nftList2.push({
          image: "https://tokensimage.github.io/images/BSC/WorDAO/NFT/genesis.png",
          id: list2[i],
          name: "Genesis"
        });
      }

      this.nftList = nftList.concat(nftList2);
    },
  }
}
</script>

<style scoped>

</style>