<template>
  <div class="min-h-screen bg-bgColor flex flex-col relative">

    <div style="padding-top: 64px">
      <router-view></router-view>
    </div>
    <div class="w-full bg-red fixed flex flex-row items-center justify-between" style="height: 64px; background-color: #2A2929;">
      <div class="flex flex-row">
        <div @click="show = !show" class="menu" style="margin-left: 10px">
          <i class="el-icon-s-operation" style="color: #FFFFFFCC; font-size: 24px"></i>
        </div>
        <img class="mr-6 ml-6 subNav" src="../assets/images/logo.png" width="99px" height="auto" alt="">
        <img class="mr-6 ml-2 menu" src="../assets/images/logo.png" width="49px" height="auto" alt="">
      </div>

      <div class="flex flex-wrap items-center">
        <div class="subNav" @click="toView('home')">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('Home')}}</div>
        </div>
        <div class="subNav" @click="toView('about')">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('About')}}</div>
        </div>
        <div class="subNav" @click="toView('develop')">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('Develop')}}</div>
        </div>
<!--        <router-link class="subNav" to="/national">-->
<!--          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{-->
<!--            fontSize: fixScale(26, 14)-->
<!--          }]">{{$t('National')}}</div>-->
<!--        </router-link>-->
        <div class="subNav" @click="toView('question')">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('Question')}}</div>
        </div>
        <router-link class="subNav" to="/invite">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('Invite')}}</div>
        </router-link>
        <router-link class="subNav" to="/info">
          <div class="font-medium mr-6"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('Info')}}</div>
        </router-link>
        <router-link class="subNav" to="/ido">
          <div class="font-medium mr-6" style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">{{$t('IDO')}}</div>
        </router-link>
        <div class="flex flex-row items-center justify-center">
          <div @click="language('en')" class="font-medium mr-2 fontFix h-full"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">English</div>
          <div style="height: 80%; width: 2px; background-color: #FFFFFF8C"></div>
          <div @click="language('zh')" class="font-medium ml-2 fontFix"  style="color: #FFFFFFCC" :style="[{
            fontSize: fixScale(26, 14)
          }]">中文</div>
          <div @click="connect" class="ml-2 flex flex-row items-center mr-2 md: ml-4">
<!--            style="width: 20px; height: 18px"-->
            <img src="../assets/images/wallet.png" alt="" :style="[{
              width: fixScale(32, 20.5),
              height: fixScale(28, 18),
            }]">
            <div class="ml-1 fontFix" style="color: #FFFFFFCC" :style="[{
              fontSize: fixScale(26, 14)
            }]">{{ fixWallet }}</div>
          </div>
          <router-link to="/nft" class="ml-2 flex flex-row items-center mr-2 md:mr-4">
<!--            style="width: 20px; height: 20px"-->
            <img src="../assets/images/nft-head.png" alt="" :style="[{
              width: fixScale(42, 20),
              height: fixScale(42, 20),
            }]">
          </router-link>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="left" :style="{ width: '40%', height: '100%'}" duration="0.2">
      <div class="w-full h-full" style="
      padding-top: 44px;
      background-color: #2A2929;
      color: #FFFFFFCC">
        <div @click="toView('home')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('Home')}}
        </div>
        <div @click="toView('about')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('About')}}
        </div>
        <div @click="toView('develop')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('Develop')}}
        </div>
<!--        <router-link to="/national" class="flex flex-col justify-center" style="height: 40px;">-->
<!--          {{$t('National')}}-->
<!--        </router-link>-->
        <div @click="toView('question')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('Question')}}
        </div>
        <div @click="toRoute('/invite')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('Invite')}}
        </div>
        <div @click="toRoute('/info')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('Info')}}
        </div>
        <div @click="toRoute('/ido')" class="flex flex-col justify-center" style="height: 40px;">
          {{$t('IDO')}}
        </div>
<!--        <div class="flex flex-col justify-center" style="height: 40px;">-->
<!--          English-->
<!--        </div>-->
<!--        <div class="flex flex-col justify-center" style="height: 40px;">-->
<!--          中文-->
<!--        </div>-->

      </div>
    </van-popup>
  </div>
</template>

<script>
import {connect, fixWallet, getWallet, startListenWallet} from "../utils/contract";

import i18n from "@/i18n";
import {log} from "@/utils/tools";
// import ERC20Abi from "@/assets/abi/ERC20Abi.json";
// import BigNumber from "bignumber.js";

export default {
  name: 'Service',
  props: {
    msg: String
  },
  watch: {

  },
  mounted() {
    let self = this;
    self.$store.commit("updateWallet", getWallet())
    self.$store.commit("updateFixWallet", fixWallet())
    startListenWallet(() => {
      self.$store.commit("updateWallet", getWallet())
      self.$store.commit("updateFixWallet", fixWallet())
    });

    window.onresize = () => {
      return (() => {
        const width = document.body.clientWidth
        const scale = width < 1920 ? width/1920 : 1;
        self.$store.commit("setScale", scale)
      })()
    }
    const width = document.body.clientWidth
    let scale = width < 1920 ? width/1920 : 1;

    setTimeout((() => {
      const width = document.body.clientWidth
      scale = width < 1920 ? width/1920 : 1;
      self.$store.commit("setScale", scale)
    }, 500))
    self.$store.commit("setScale", scale)

    const path = window.location.pathname;
    if (path.toLowerCase().startsWith("/0x")) {
      log(`self.$router.currentRoute.path = ${self.$router.currentRoute.path}`)
      if (self.$router.currentRoute.path != "/invite") {
        self.$router.push("/invite");
      }
    }
  },
  computed: {
    bgColor() {
      return "bg-"+this.$store.state.bgColor;
    },
    wallet() {
      return this.$store.state.wallet;
    },
    fixWallet() {
      return this.$store.state.fixWallet;
    },
    fixTimes() {
      return (times) => {
        if (times == 0) return ""
        const unixTimestamp = times
        const date = new Date(unixTimestamp*1000);
        // log("Unix Timestamp:",unixTimestamp)
        // log("Date Timestamp:",date.getTime())
        // log(date)
        // log("Date: "+date.getDate()+
        //     "/"+(date.getMonth()+1)+
        //     "/"+date.getFullYear()+
        //     " "+date.getHours()+
        //     ":"+date.getMinutes()+
        //     ":"+date.getSeconds());
        return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate();
      }
    },
    fixScale() {
      let self = this;
      return (value, min) => {
        let _value = value*self.$store.state.scale;
        if (_value < min) {
          return min+"px";
        }
        return _value+"px";
      }
    }
  },
  data() {
    return {
      show: false,
      nodeInfo: [false, false],
      drawer: false,
      index: -1,
      userInfo: {
        id: 0,
        joinTime: 0,
      },
    }
  },
  methods: {
    connect() {
      connect();
    },
    toRoute(_path) {
      this.show = false;
      const path = this.$router.currentRoute.path;
      log(`path = ${path}  _path = ${_path}`)
      if (path == _path) return;
      this.$router.push(_path);
    },
    toView(_id) {
      this.show = false;
      const path = this.$router.currentRoute.path;
      log(path)
      if (path == "/home") {
        if (_id == "home") {
          document.documentElement.scrollTop = 0;
          return;
        }
        document.getElementById(`${_id}`).scrollIntoView();
        return;
      }
      this.$router.push("/home").then(() => {
        document.getElementById(`${_id}`).scrollIntoView();
      });

    },
    handleMenu() {
      this.drawer = true;
    },
    toHome() {
      this.$router.push({path: "/"});
    },
    tableChanged(index) {
      if (index == 5) {
        window.open("https://t.me/FirstDao1")
        return;
      }
      const path = this.$router.currentRoute.path;
      if (index == 0 && path != "/group") {
        this.$router.push({path: "/group"});
        this.drawer = false;
        return;
      }

      if (index == 1 && path != "/node") {
        this.$router.push({path: "/node"});
        this.drawer = false;
        return;
      }

      if (index == 3) {
        //zh-cn
        const lan = "zh";
        localStorage.setItem("lang", lan);
        i18n.locale = "zh";
        return;
      }
      if (index == 4) {
        // english
        const lan = "en";
        localStorage.setItem("lang", lan);
        i18n.locale = "en";
      }

      if (index == 6 && path != "/beInviter"){
        this.$router.push({path: "/beInviter"});
        this.drawer = false;}
    },
    language(value) {
      localStorage.setItem("lang", value)
      i18n.locale = value;
    }
  }
}
</script>
<style>
.el-drawer.rtl {
  background-color: transparent;
  box-shadow: none;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media all and (max-width: 768px) {
  .menu {
    display: block;
  }
  .subNav {
    display: none;
  }
  .fontFix {
    font-size: 12px;
  }
  .leftFix {
    margin-left: 0px;
  }
}

@media all and (min-width: 768px) {
  .menu {
    display: none;
  }
  .subNav {
    display: block;
  }
  .fontFix {
    font-size: 16px;
  }
  .leftFix {
    margin-left: 20px;
  }
}

</style>
