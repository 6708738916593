export const copy = (self, value) => {

    self.$copyText(
        value
    ).then( () => {
            self.$notify({
                title: self.$t('Tips'),
                message: self.$t('Copy Success'),
            });
        },
        () => {
            self.$notify({
                title: self.$t('Tips'),
                message: self.$t('Copy Failed'),
            });
        }
    );
}

export const fixWallet = (_wallet, start, end) => {
    if (_wallet && _wallet != null && _wallet.length > 10) {
        return _wallet.substr(0, start) +"***" + _wallet.substr(_wallet.length-end, _wallet.length)
    }
}

export const log = (msg) => {
    if (process.env.NODE_ENV == "development") {
        console.log(msg)
    }
}

export const errFormatMsg = (e, msg) => {
    let errorList = e.message.replace("Internal JSON-RPC error.", "").split("execution reverted:");
    if (errorList.length > 1) {
        let result = errorList[1].split("\",")[0];
        return result;
    }
    return msg;
}
