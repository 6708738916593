var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full text-white"},[_c('div',{staticClass:"font-bold mb-5 mt-10",style:([{
      fontSize: _vm.fixScale(80, 20)
    }])},[_c('span',{staticStyle:{"color":"#4D9EF2"}},[_vm._v(_vm._s(_vm.$t('IDO Ends'))+" ")]),_vm._v(_vm._s(_vm.$t('Countdown')))]),_c('div',{staticClass:"max-w-min mx-auto"},[_c('div',{staticClass:"flex flex-row items-center justify-center"},[_c('div',{staticClass:"box flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
        height: _vm.fixScale(207, 51)
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }])},[_vm._v(_vm._s(_vm.hours))])]),_c('div',[_c('img',{style:([{
          width: _vm.fixScale(18, 6),
          height: _vm.fixScale(75, 25),
          marginLeft: _vm.fixScale(25, 3),
          marginRight: _vm.fixScale(25, 3)
        }]),attrs:{"src":require("../../assets/images/ido/m.png"),"alt":""}})]),_c('div',{staticClass:"box flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
        height: _vm.fixScale(207, 51)
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }])},[_vm._v(_vm._s(_vm.minutes))])]),_c('div',[_c('img',{style:([{
          width: _vm.fixScale(18, 6),
          height: _vm.fixScale(75, 25),
          marginLeft: _vm.fixScale(25, 3),
          marginRight: _vm.fixScale(25, 3)
        }]),attrs:{"src":require("../../assets/images/ido/m.png"),"alt":""}})]),_c('div',{staticClass:"box flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
        height: _vm.fixScale(207, 51)
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(100, 20),
          fontFamily: 'PingFang SC',
        }])},[_vm._v(_vm._s(_vm.seconds))])])]),_c('div',{staticClass:"mt-3 flex flex-row items-center justify-center"},[_c('div',{staticClass:"flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(42, 10),
        }])},[_vm._v(_vm._s(_vm.$t('HOURS')))])]),_c('div',[_c('div',{style:([{
          width: _vm.fixScale(18, 6),
          marginLeft: _vm.fixScale(25, 3),
          marginRight: _vm.fixScale(25, 3)
        }]),attrs:{"alt":""}})]),_c('div',{staticClass:"flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(42, 10),
        }])},[_vm._v(_vm._s(_vm.$t('MINUTES')))])]),_c('div',[_c('div',{style:([{
          width: _vm.fixScale(18, 6),
          marginLeft: _vm.fixScale(25, 3),
          marginRight: _vm.fixScale(25, 3)
        }]),attrs:{"alt":""}})]),_c('div',{staticClass:"flex flex-wrap items-center justify-center",style:([{
        width: _vm.fixScale(240, 60),
      }])},[_c('div',{staticClass:"font-bold",style:([{
          fontSize: _vm.fixScale(42, 10),
        }])},[_vm._v(_vm._s(_vm.$t('SECONDS')))])])]),_c('div',{staticClass:"w-full mt-8 flex flex-row justify-between",style:([{
        fontSize: _vm.fixScale(70, 20)
      }])},[_c('div',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.fixPrice(_vm.idoInfo.totalUsdt, 18, 0))+" "),_c('span',{style:([{
        fontSize: _vm.fixScale(40, 12)
      }])},[_vm._v("USDT")])]),_c('div',[_vm._v(_vm._s(_vm.idoInfo.buyersLength))])]),_c('div',{staticClass:"w-full flex flex-row justify-between font-medium",style:([{
        fontSize: _vm.fixScale(40, 12)
      }])},[_c('div',[_vm._v(_vm._s(_vm.$t('IDO Total Amount')))]),_c('div',[_vm._v(_vm._s(_vm.$t('Number of IDO')))])])]),_c('div',{staticClass:"font-medium mt-5",style:([{
      fontSize: _vm.fixScale(40, 12)
    }])},[_vm._v(_vm._s(_vm.$t('IDO Price'))+": 1 WOR = "+_vm._s(_vm.price)+" USDT")]),_c('div',{staticClass:"max-w-min mx-auto"},[_c('div',{staticClass:"w-full flex rowCol items-center justify-between"},[_c('div',{staticClass:"rounded-xl mt-5 flex flex-col",staticStyle:{"border":"1px solid #FFFFFF33"},style:([{
        width: _vm.fixScale(830, 300),
        height: _vm.fixScale(605, 218)
      }])},[_c('div',{staticClass:"font-bold flex flex-col justify-center",style:([{
          fontSize: _vm.fixScale(46, 20),
          height: _vm.fixScale(138, 40),
        }])},[_c('div',[_vm._v(_vm._s(_vm.$t('Basic IDO Quota')))])]),_c('div',{staticClass:"mx-auto",staticStyle:{"width":"80%","height":"1px","background-color":"#FFFFFF33"}}),_c('div',{staticClass:"font-medium flex-1 flex flex-wrap items-center justify-center",style:([{
          fontSize: _vm.fixScale(42, 20)
        }])},[_vm._v("100 USDT "+_vm._s(_vm.$t('Expected'))),_c('br'),_vm._v(_vm._s(_vm.$t('to get'))+" "+_vm._s(_vm.getTokens(100, _vm.round))+" WOR")]),_c('div',{staticClass:"mx-auto rounded-xl font-bold flex flex-wrap items-center justify-center",staticStyle:{"background":"linear-gradient(0deg, #b0b0b0, #8c8c8c)"},style:([{
          fontSize: _vm.fixScale(40, 18),
          marginBottom: _vm.fixScale(96, 20),
          width: _vm.fixScale(440, 120),
          height: _vm.fixScale(100, 40),
          background: _vm.roundEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
          disabled: !_vm.roundEnabled
        }]),on:{"click":_vm.deposit}},[_vm._v(_vm._s(_vm.roundEnabled ? _vm.$t('Deposit') : _vm.$t('Please Wait')))])]),_c('div',{staticClass:"rounded-xl mt-5 flex flex-col",staticStyle:{"border":"1px solid #FFFFFF33"},style:([{
        width: _vm.fixScale(830, 300),
        height: _vm.fixScale(605, 218)
      }])},[_c('div',{staticClass:"font-medium flex flex-col justify-center",style:([{
          fontSize: _vm.fixScale(42, 18),
          height: _vm.fixScale(138, 40),
        }])},[_c('div',[_vm._v(_vm._s(_vm.$t('WOR-DAO Partner IDO Quota')))])]),_c('div',{staticClass:"mx-auto rounded-xl font-bold flex flex-wrap justify-center items-center",style:([{
          fontSize: _vm.fixScale(40, 16),
          width: _vm.fixScale(480, 240),
          height: _vm.fixScale(100, 40),
          background: 'linear-gradient(0deg, #1E84E5, #7CD2FB)'
        }])},[_vm._v(_vm._s(_vm.$t('Join WOR-DAO Partner')))]),_c('div',{staticClass:"mt-3 mx-auto",staticStyle:{"width":"80%","height":"1px","background-color":"#FFFFFF33"}}),_c('div',{staticClass:"font-medium flex-1 flex flex-wrap items-center justify-center",style:([{
          fontSize: _vm.fixScale(42, 20)
        }])},[_vm._v("200 USDT "+_vm._s(_vm.$t('Expected'))),_c('br'),_vm._v(_vm._s(_vm.$t('to get'))+" "+_vm._s(_vm.getTokens(200, 0))+" WOR")]),_c('div',{staticClass:"mx-auto rounded-xl font-bold flex flex-wrap items-center justify-center",style:([{
          fontSize: _vm.fixScale(40, 18),
          marginBottom: _vm.fixScale(38, 10),
          width: _vm.fixScale(440, 120),
          height: _vm.fixScale(100, 40),
          background: _vm.roundEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
          disabled: !_vm.roundEnabled
        }]),on:{"click":_vm.depositPartner}},[_vm._v(_vm._s(_vm.roundEnabled ? _vm.$t('Deposit') : _vm.$t('Please Wait')))])])]),_c('div',{staticClass:"mx-auto rounded-xl mt-5 pb-2 mx-2 flex flex-col",staticStyle:{"border":"1px solid #FFFFFF33"},style:([{
        width: _vm.fixScale(1719, 300),
        // height: fixScale(605, 218),
        // minHeight: fixScale(605, 218),
      }])},[_c('div',{staticClass:"font-medium flex flex-col justify-center",style:([{
          fontSize: _vm.fixScale(46, 20),
          height: _vm.fixScale(138, 40),
        }])},[_c('div',[_vm._v(_vm._s(_vm.$t('Rule')))])]),_vm._m(0),_c('div',{staticClass:"font-medium flex-1 flex flex-wrap text-left mx-5 mt-2",style:([{
          fontSize: _vm.fixScale(42, 20)
        }])},[_vm._v(_vm._s(_vm.$t('Round 1'))+": 1 WOR = 0.19USDT"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Round 2'))+": 1 WOR = 0.20USDT"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Round 3'))+": 1 WOR = 0.21USDT"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('One wallet limit 100 USDT'))),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('NFT Rule'))+":"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Invite 10 partners into IDO can be successful to receive a NFT, invited 20 to take 2, 30 to take 3, and so on, get the address of NFT can obtain a total of 200USDT lines'))+" ")])]),_c('div',{staticClass:"font-bold mt-5",style:([{
        fontSize: _vm.fixScale(46, 20)
      }])},[_vm._v(_vm._s(_vm.$t('My Subscription')))]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{style:([{
          fontSize: _vm.fixScale(46, 16)
        }])},[_vm._v(_vm._s(_vm.$t('WOR Waiting for Release')))]),_c('div',{style:([{
          fontSize: _vm.fixScale(46, 16)
        }])},[_vm._v(_vm._s(_vm.$t('WOR Available')))])]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{style:([{
          fontSize: _vm.fixScale(46, 16)
        }])},[_vm._v(_vm._s(0.00))]),_c('div',{style:([{
          fontSize: _vm.fixScale(46, 16)
        }])},[_vm._v(_vm._s(_vm.fixPrice(_vm.userInfo.canClaimValue, 8, 0)))])]),_c('div',{staticClass:"mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl",style:([{
          fontSize: _vm.fixScale(50, 20),
          width: _vm.fixScale(440, 100),
          height: _vm.fixScale(100, 40),
          background: _vm.userInfo.claimEnabled ? 'linear-gradient(0deg, #1E84E5, #7CD2FB)' : 'linear-gradient(0deg, #b0b0b0, #8c8c8c)',
        }]),on:{"click":_vm.claimTap}},[_vm._v(_vm._s(_vm.$t('Claim')))]),_c('div',{staticClass:"mx-auto rounded-xl mt-5 pb-2 mx-2 flex flex-col",staticStyle:{"border":"1px solid #FFFFFF33"},style:([{
        width: _vm.fixScale(1719, 300),
        // height: fixScale(605, 218),
        // minHeight: fixScale(605, 218),
      }])},[_c('div',{staticClass:"mx-5 font-medium flex flex-col justify-center",style:([{
          fontSize: _vm.fixScale(46, 20),
          // height: fixScale(138, 40),
        }])},[_c('div',[_vm._v(_vm._s(_vm.$t("Move your finger, forward it, and witness the power of consensus Maybe one of your retweets is driving a huge increase")))])]),_vm._m(1),_c('div',{staticClass:"flex-1 flex flex-wrap text-center mx-5 mt-2",style:([{
          fontSize: _vm.fixScale(42, 20),
        }])},[_vm._v(_vm._s(_vm.$t("2022#Dark HorsePotential Proiect#Block Forest ecoloay is comina. Block Forest. an agaregation platform built on the #Bsc public chain, will give you another hundred times the opportunity. Unique mechanism of mutual wealth! On May 22, PANCAKE will be launched soon."))+" "),_c('div',{staticClass:"w-full text-center"},[_vm._v(_vm._s(_vm.$t('2022-WOR DAO-')))]),_c('div',{staticClass:"mt-1 w-full text-center break-all",staticStyle:{"color":"#4C9DF0"}},[_vm._v(_vm._s(_vm.$t('Referral Link'))+": https://www.wordao.app/"+_vm._s(_vm.wallet))])]),_c('div',{staticClass:"mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl",staticStyle:{"background":"linear-gradient(0deg, #1E84E5, #7CD2FB)"},style:([{
          fontSize: _vm.fixScale(50, 20),
          width: _vm.fixScale(440, 100),
          height: _vm.fixScale(100, 40)
        }]),on:{"click":_vm.copy2}},[_vm._v(_vm._s(_vm.$t('Copy')))]),_c('div',{staticClass:"mt-5 mb-2 font-bold",style:([{
          fontSize: _vm.fixScale(46, 20),
        }])},[_vm._v(_vm._s(_vm.$t('Contract address'))+":")]),_c('div',{staticClass:"mx-auto px-4 py-2 rounded-md max-w-min",style:([{
          fontSize: _vm.fixScale(36, 12),
          background: '#353434'
        }])},[_vm._v(_vm._s(_vm.contract))]),_c('div',{staticClass:"mx-auto font-bold mt-6 mb-8 flex flex-wrap items-center justify-center rounded-xl",staticStyle:{"background":"linear-gradient(0deg, #1E84E5, #7CD2FB)"},style:([{
          fontSize: _vm.fixScale(50, 20),
          width: _vm.fixScale(440, 100),
          height: _vm.fixScale(100, 40)
        }]),on:{"click":_vm.copy}},[_vm._v(_vm._s(_vm.$t('Copy')))])]),_c('div',{staticStyle:{"height":"40px"}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 w-full px-2"},[_c('div',{staticClass:"w-full",staticStyle:{"height":"1px","background-color":"#FFFFFF33"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 w-full px-2"},[_c('div',{staticClass:"w-full",staticStyle:{"height":"1px","background-color":"#FFFFFF33"}})])}]

export { render, staticRenderFns }